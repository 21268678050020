import { clsx } from 'clsx';
import { compact, groupBy, map, orderBy, size } from 'lodash-es';
import {
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { ReactNode } from 'react';
import { useHasRole } from '../../hooks/users';
import { useQuery } from '@tanstack/react-query';

import { DATE_FORMATS, formatDate } from '../../util/dates';
import { getLiveLink, getTestLinks } from 'util/surveys';
import { getNonAdminIncidenceDisplay } from 'util/incidence';
import { orderQuotasByOptions } from 'util/questionQuotas';
import {
  Question,
  QuestionWithResults,
  Survey,
  SurveyAudienceSlice,
  SurveyVariable,
  SurveyWave,
  SurveyWithResults,
} from '../../types/domainModels';
import { questionQuotaQueries } from 'hooks/backend/questionQuotas';
import { RechartsXTickProps } from '../../types/internal';
import { surveyQueries } from 'hooks/backend/surveys';
import { useModal } from '../../hooks/modals';
import { variableQuotaQueries } from 'hooks/backend/surveyVariableQuotas';

import AudienceEditModal from '../common/AudienceEditModal';
import CompleteBadge from 'components/common/CompleteBadge';
import CopyToClipboard from 'components/common/CopyToClipboard';
import Dropdown, {
  DropdownButton,
  DropdownItem,
} from 'components/common/Dropdown';
import ErrorDisplay from '../common/ErrorDisplay';
import Hyperlink from 'components/common/Hyperlink';
import Icon from 'components/common/Icon';
import IndexCard from '../common/IndexCard';
import ParticipantsModal from '../common/ParticipantsModal';
import ProgressBar from '../common/ProgressBar';
import {
  QuotaProgress,
  QuotasHeader,
  QuotasProgressHeader,
  QuestionQuotasDropdown,
  Requirements,
  VariableQuotasDropdown,
} from './Quotas';
import SurveyStatusBadge from '../common/SurveyStatusBadge';
import TitledCard, { TitledCardHeader } from './TitledCard';
import VerticalDotsButton from 'components/common/VerticalDotsButton';
import WordSeparator from 'components/common/WordSeparator';

const SurveySummary = ({
  selectedWave,
  survey,
  viewingWaveId,
  waveIds,
}: {
  selectedWave: SurveyWave | undefined;
  survey: SurveyWithResults;
  viewingWaveId: number | null | undefined;
  waveIds: number[];
}) => {
  const surveyWithResults = survey;

  const { data: wavesData } = useQuery(
    surveyQueries.waves({ surveyId: survey.id, waveIds }),
  );
  const waves = wavesData?.waves ?? [];

  return (
    <div className="space-y-4">
      <SummaryInfo
        selectedWave={selectedWave}
        survey={survey}
        viewingWaveId={viewingWaveId}
      />
      <RespondentsOverview survey={survey} waveIds={waveIds} waves={waves} />
      <SurveyAudience survey={surveyWithResults} waveIds={waveIds} />
      {waveIds.length <= 1 && (
        <QuestionQuotas
          questions={survey.questions}
          survey={survey}
          waveIds={waveIds}
        />
      )}
      <VariableQuotas
        questions={survey.questions}
        survey={survey}
        surveyVariables={survey.surveyVariables}
        waveIds={waveIds}
      />
    </div>
  );
};

export default SurveySummary;

export const SkeletonSurveySummary = () => {
  const topStatsPlaceholders = [1, 2, 3];

  return (
    <div className="space-y-4">
      <div className="h-12 flex items-center">
        <div className="w-1/2 h-6 bg-light-grey" />
      </div>
      {/* Mocks out the shape of the summary info. */}
      <IndexCard>
        <div className="grid grid-cols-3 animate-pulse p-4">
          {topStatsPlaceholders.map((num) => {
            return (
              <div key={num} className="space-y-2">
                <div className="w-1/2 h-4 bg-light-grey" />
                <div className="w-3/4 h-6 bg-light-grey" />
              </div>
            );
          })}
        </div>
      </IndexCard>
      {/* Mocks out the line chart card. */}
      <IndexCard>
        <div className="h-72 space-y-2 animate-pulse p-4">
          <div className="w-1/4 h-4 bg-light-grey" />
          <div className="w-1/2 h-2 bg-light-grey" />
        </div>
      </IndexCard>
      {/* Mocks out the shape of the quotas card. */}
      <IndexCard>
        <div className="h-40 animate-pulse p-4 space-y-2">
          <div className="w-1/2 h-4 bg-light-grey" />
          <div className="w-1/4 h-2 bg-light-grey" />
        </div>
      </IndexCard>
    </div>
  );
};

const SummaryInfo = ({
  selectedWave,
  survey,
  viewingWaveId,
}: {
  selectedWave: SurveyWave | undefined;
  survey: SurveyWithResults;
  viewingWaveId: number | null | undefined;
}) => {
  const isAdmin = useHasRole('admin');

  let launchedAt = '';
  let finishedAt = '';
  const isViewingAllWaves = viewingWaveId === null;

  if (isViewingAllWaves) {
    launchedAt = survey.waves[0].startedAt;
    finishedAt = survey.waves[survey.waves.length - 1].endedAt;
  } else {
    const wave = survey.waves.find(({ id }) => id === viewingWaveId);
    if (wave) {
      launchedAt = wave.startedAt;
      finishedAt = wave.endedAt;
    }
  }

  // The waveId property of the survey represents the current wave for the survey.
  // So if you're not viewing that ID, you're viewing a previous wave.
  const isPreviousWave =
    survey.waves.length > 1 && viewingWaveId !== survey.waveId;

  const formattedLaunchedAt = formatDate(launchedAt, {
    format: DATE_FORMATS.SURVEY_LAUNCHED_AT,
  });
  const formattedFinishedAt = finishedAt
    ? formatDate(finishedAt, {
        format: DATE_FORMATS.SURVEY_LAUNCHED_AT,
      })
    : 'Now';

  const { previewLink, testLink } = getTestLinks({ survey });
  const liveLink = getLiveLink({ isAdmin, survey });

  return (
    <IndexCard>
      <div className="grid grid-cols-3">
        <div>
          <SummaryInfoSection title="Status">
            {isViewingAllWaves ? (
              '---'
            ) : isPreviousWave ? (
              <CompleteBadge />
            ) : (
              <SurveyStatusBadge survey={survey} />
            )}
          </SummaryInfoSection>
        </div>
        <div className="border-l border-light-grey">
          <SummaryInfoSection title="In-Field Dates">
            <div className="text-base">
              {formattedLaunchedAt} - {formattedFinishedAt}
            </div>
          </SummaryInfoSection>
        </div>
        <div className="border-l border-light-grey">
          <SummaryInfoSection title="Current Incidence">
            <div className="text-base">
              <CurrentIncidence selectedWave={selectedWave} survey={survey} />
            </div>
          </SummaryInfoSection>
        </div>
        <div className="col-span-3 border-t border-light-grey">
          <SummaryInfoSection title="Links">
            <div className="flex space-x-4">
              <div className="flex space-x-2">
                <Hyperlink href={testLink}>Test Link</Hyperlink>
                <CopyToClipboard text={testLink} />
              </div>
              <div className="flex space-x-2">
                <Hyperlink href={previewLink}>Preview Link</Hyperlink>
                <CopyToClipboard text={previewLink} />
              </div>
              {liveLink && (
                <div className="flex space-x-2">
                  <Hyperlink href={liveLink}>Live Link</Hyperlink>
                  <CopyToClipboard text={liveLink} />
                </div>
              )}
            </div>
          </SummaryInfoSection>
        </div>
      </div>
    </IndexCard>
  );
};

const SummaryInfoSection = ({
  children,
  title,
}: {
  children: ReactNode;
  title: string;
}) => {
  return (
    <div className="p-4 space-y-2">
      <div className="text-2xs text-gray-600 font-medium uppercase">
        {title}
      </div>
      {children}
    </div>
  );
};

const CurrentIncidence = ({
  selectedWave,
  survey,
}: {
  selectedWave: SurveyWave | undefined;
  survey: SurveyWithResults;
}) => {
  const isAdmin = useHasRole('admin');
  const waveId = selectedWave?.id;

  const {
    data: incidence,
    isError,
    isLoading,
    isSuccess,
  } = useQuery(surveyQueries.incidence({ surveyId: survey.id, waveId }));

  // We don't yet support calculating incidence for "All waves". So we require
  // a wave to be selected to show the incidence.
  if (!waveId) {
    return '---';
  }

  if (isLoading) {
    return <div className="animate-pulse h-5 w-1/2 bg-light-grey" />;
  }

  if (isError) {
    return <p className="text-error-d-700">Failed to load incidence</p>;
  }

  if (isSuccess) {
    if (incidence.current === null) {
      return '---';
    }

    if (isAdmin) {
      return (
        <div className="flex items-center space-x-2">
          <span>{incidence.current.percent}</span>
          {/* The estimated incidence will be null for previous waves. */}
          {incidence.estimated && (
            <span className="text-sm">(est. {incidence.estimated})</span>
          )}
        </div>
      );
    }

    if (!incidence.estimated) {
      return '---';
    }

    return getNonAdminIncidenceDisplay({
      actualIncidence: Number(incidence.current.percent.split('%')[0]),
      estimatedIncidence: incidence.estimated,
    });
  }

  return null;
};

const RespondentsOverview = ({
  survey,
  waveIds,
  waves,
}: {
  survey: SurveyWithResults;
  waveIds: number[];
  waves: SurveyWave[];
}) => {
  const isAdmin = useHasRole('admin');

  const {
    isOpen: isParticipantModalOpen,
    onCloseModal: onCloseParticipantModalOpen,
    setIsOpen: setIsParticipantModalOpen,
  } = useModal();

  const {
    data: surveyCompletes = [],
    dataUpdatedAt: lastFetchedCompletesAt,
    error: loadCompletesError,
    isError: hasLoadCompletesError,
    isLoading: isLoadingCompletes,
  } = useQuery(surveyQueries.surveyCompletes({ surveyId: survey.id, waveIds }));

  const isCurrentWave = waveIds.length === 1 && waveIds[0] === survey.waveId;
  const waveCompletes = waves
    .map((w) => w.completes)
    .reduce((acc, completes) => acc + completes, 0);
  const waveTarget = waves
    .map((w) => w.target)
    .reduce((acc, target) => acc + target, 0);

  const chartData = surveyCompletes.map(({ completes, time }) => {
    return {
      completes,
      time: formatDate(time, {
        format: DATE_FORMATS.LINE_CHART,
        parseFormat: DATE_FORMATS.SURVEY_COMPLETE,
      }),
    };
  });
  const percentage = waveTarget ? Math.min(waveCompletes / waveTarget, 1) : 0;

  return (
    <IndexCard>
      <div className="p-4 space-y-4">
        <div className="flex justify-between">
          <div className="flex items-center space-x-2">
            <div>Respondents</div>
            <div className="flex items-center">
              <ProgressBar percentage={percentage} />
              <span>
                {waveCompletes} / {waveTarget}
              </span>
            </div>
          </div>

          {isAdmin && (
            <Dropdown
              button={
                <DropdownButton as="div">
                  <VerticalDotsButton ariaLabel="Quotas Options" />
                </DropdownButton>
              }
            >
              {isCurrentWave && (
                <DropdownItem
                  as="button"
                  icon={<Icon id="plus" />}
                  onClick={() => {
                    setIsParticipantModalOpen(true);
                  }}
                  type="button"
                >
                  Add Participants
                </DropdownItem>
              )}
            </Dropdown>
          )}
        </div>

        {surveyCompletes.length > 0 ? (
          <div className="p-2 pb-4">
            <ResponsiveContainer height={300} width="100%">
              <LineChart
                data={chartData}
                margin={{
                  top: 20,
                  right: 30,
                  left: 0,
                  bottom: 30,
                }}
                style={{ width: '100%' }}
              >
                <XAxis
                  dataKey="time"
                  tick={(props: RechartsXTickProps) => (
                    <SurveyLineChartXTick {...props} />
                  )}
                  type="category"
                />
                <YAxis dataKey="completes" tick={{ fontSize: '12px' }} />
                <Tooltip />
                <Line dataKey="completes" stroke="#006451" />
              </LineChart>
            </ResponsiveContainer>
          </div>
        ) : (
          <div className="h-72">
            {isLoadingCompletes && (
              <div className="space-y-2 animate-pulse">
                <div className="w-1/4 h-4 bg-light-grey" />
                <div className="w-1/2 h-2 bg-light-grey" />
              </div>
            )}
            {hasLoadCompletesError && (
              <ErrorDisplay
                message={`Failed to load survey completes. (${loadCompletesError.message})`}
              />
            )}
            {!isLoadingCompletes && !loadCompletesError && (
              <>
                <p className="text-sm">Waiting for completes...</p>
                <div className="mt-2 text-dark-grey text-xs">
                  Last checked:{' '}
                  {formatDate(lastFetchedCompletesAt, {
                    format: DATE_FORMATS.TIME,
                  })}
                </div>
              </>
            )}
          </div>
        )}
      </div>

      {isParticipantModalOpen && (
        <ParticipantsModal
          onCloseModal={onCloseParticipantModalOpen}
          participants={waveTarget}
          surveyID={survey.id}
        />
      )}
    </IndexCard>
  );
};

const SurveyLineChartXTick = ({ payload, x, y }: RechartsXTickProps) => {
  return (
    <g transform={`translate(${x},${y})`}>
      <text
        dy={16}
        fill="#666"
        fontSize="12px"
        textAnchor="end"
        transform="rotate(-35)"
        x={0}
        y={0}
      >
        {payload.value}
      </text>
    </g>
  );
};

const SurveyAudience = ({
  survey,
  waveIds,
}: {
  survey: SurveyWithResults;
  waveIds: number[];
}) => {
  const isAdmin = useHasRole('admin');

  const {
    isOpen: isAudienceModalOpen,
    onCloseModal: onCloseAudienceModal,
    setIsOpen: setIsAudienceModalOpen,
  } = useModal();

  const { data: surveyDemographicQuotas = [] } = useQuery(
    surveyQueries.surveyAudienceSlices({ surveyId: survey.id, waveIds }),
  );

  const demographicQuotas: Record<
    number,
    { question: Question; quotas: SurveyAudienceSlice[] }
  > = {};
  surveyDemographicQuotas.map((quota) => {
    const question = quota.audienceSliceCategory.question;

    if (demographicQuotas[question.id]) {
      demographicQuotas[question.id].quotas.push(quota);
    } else {
      demographicQuotas[question.id] = { question, quotas: [quota] };
    }
  });

  if (!isAdmin && size(demographicQuotas) === 0) {
    return null;
  }

  return (
    <TitledCard
      header={
        <TitledCardHeader
          rightContent={
            <Dropdown
              button={
                <DropdownButton as="div">
                  <VerticalDotsButton ariaLabel="Audience Options" />
                </DropdownButton>
              }
            >
              <DropdownItem
                as="button"
                icon={<Icon id="pencil" />}
                onClick={() => {
                  setIsAudienceModalOpen(true);
                }}
                type="button"
              >
                Edit Audience
              </DropdownItem>
            </Dropdown>
          }
        >
          Audience
        </TitledCardHeader>
      }
    >
      {/*
       * We only show this messaging for admins since non-admins can't edit the
       * audience for a launched survey.
       */}
      {isAdmin && size(demographicQuotas) === 0 && (
        <p className="p-4">No audience set up.</p>
      )}

      <div className="divide-y divide-gray-400">
        {map(demographicQuotas, ({ question, quotas }) => {
          return (
            <div key={question.id} className="p-4">
              <div className="space-y-2 mb-2">
                <QuotasHeader title={question.title} />
                <QuotasProgressHeader titleFirstColumn="Option(s)" />
              </div>

              <div className="divide-y divide-gray-300">
                {quotas.map((quota, quotaIdx) => {
                  const quotaRequirements: string[] = [];
                  const {
                    audienceSliceCategory,
                    numberCompleted,
                    numberNeeded,
                  } = quota;
                  const { audienceSliceCategoryAttributes, logicalModifier } =
                    audienceSliceCategory;

                  const audienceAttributes = map(
                    audienceSliceCategoryAttributes,
                    'audienceAttribute',
                  );

                  audienceAttributes.forEach((attribute) => {
                    if (attribute.enumValue) {
                      quotaRequirements.push(attribute.enumValue.title);
                    } else if (attribute.numberRange) {
                      quotaRequirements.push(
                        `between ${attribute.numberRange.start} and ${attribute.numberRange.end}`,
                      );
                    } else {
                      quotaRequirements.push('Unknown value');
                    }
                  });

                  return (
                    <div
                      key={quotaIdx}
                      className={clsx({ 'py-1': quotas.length > 1 })}
                    >
                      <QuotaProgress
                        firstColumnContent={
                          <Requirements
                            logic={logicalModifier}
                            requirements={quotaRequirements}
                          />
                        }
                        numCompletes={numberCompleted}
                        numNeeded={numberNeeded}
                      />
                    </div>
                  );
                })}
              </div>
            </div>
          );
        })}
      </div>

      {isAudienceModalOpen && (
        <AudienceEditModal
          onAudienceUpdated={() => {
            onCloseAudienceModal();
          }}
          onCloseModal={onCloseAudienceModal}
          questions={survey.questions}
          survey={survey}
        />
      )}
    </TitledCard>
  );
};

const QuestionQuotas = ({
  questions,
  survey,
  waveIds,
}: {
  questions: QuestionWithResults[];
  survey: Survey;
  waveIds: number[];
}) => {
  const isAdmin = useHasRole('admin');
  const surveyId = survey.id;

  const { data: questionQuotas = [] } = useQuery(
    questionQuotaQueries.forSurvey({ surveyId, waveIds }),
  );
  const questionQuotasByQuestionId = groupBy(questionQuotas, 'question.id');

  const quotasWithQuestions = compact(
    Object.keys(questionQuotasByQuestionId).map((questionId) => {
      const question = questions.find((q) => q.id === Number(questionId));

      return question
        ? {
            question,
            quotas: questionQuotasByQuestionId[Number(questionId)],
          }
        : null;
    }),
  );

  const orderedQuotas = orderBy(quotasWithQuestions, 'question.sort');

  if (orderedQuotas.length === 0) {
    return null;
  }

  return (
    <TitledCard header={<TitledCardHeader>Question Quotas</TitledCardHeader>}>
      <div className="divide-y divide-gray-400">
        {orderedQuotas.map(({ question, quotas }) => {
          return (
            <div key={question.id} className="p-4">
              <div className="space-y-2 mb-2">
                <QuotasHeader
                  rightContent={
                    isAdmin ? (
                      <QuestionQuotasDropdown
                        numQuotas={quotas.length}
                        question={question}
                        questions={questions}
                        waveIds={waveIds}
                      />
                    ) : undefined
                  }
                  subtitle={`Question ${question.sort}`}
                  title={question.title}
                />
                <QuotasProgressHeader titleFirstColumn="Option(s)" />
              </div>

              <div>
                {orderQuotasByOptions(quotas).map((quota, quotaIdx) => {
                  return (
                    <div key={quotaIdx}>
                      <QuotaProgress
                        firstColumnContent={
                          <Requirements
                            logic="should"
                            requirements={orderBy(quota.options, 'sort').map(
                              (o) => o.title,
                            )}
                          />
                        }
                        numCompletes={quota.count}
                        numNeeded={quota.numberNeeded}
                        type={quota.logicalModifier}
                      />
                      {quotaIdx !== quotas.length - 1 && (
                        <WordSeparator word="and" />
                      )}
                    </div>
                  );
                })}
              </div>
            </div>
          );
        })}
      </div>
    </TitledCard>
  );
};

const VariableQuotas = ({
  questions,
  survey,
  surveyVariables,
  waveIds,
}: {
  questions: QuestionWithResults[];
  survey: Survey;
  surveyVariables: SurveyVariable[];
  waveIds: number[];
}) => {
  const isAdmin = useHasRole('admin');

  const { data: variableQuotas = [] } = useQuery(
    variableQuotaQueries.forSurvey({ surveyId: survey.id, waveIds }),
  );
  const variableQuotasByVariable = groupBy(variableQuotas, 'variable.id');

  const quotasWithVariable = compact(
    Object.keys(variableQuotasByVariable).map((variableId) => {
      const variable = surveyVariables.find((v) => v.id === Number(variableId));

      return variable
        ? {
            quotas: variableQuotasByVariable[Number(variableId)],
            variable,
          }
        : null;
    }),
  );

  if (quotasWithVariable.length === 0) {
    return null;
  }

  return (
    <TitledCard header={<TitledCardHeader>Variable Quotas</TitledCardHeader>}>
      <div className="divide-y divide-gray-400">
        {quotasWithVariable.map(({ quotas, variable }) => {
          return (
            <div key={variable.id} className="p-4">
              <div className="space-y-2 mb-2">
                <QuotasHeader
                  rightContent={
                    isAdmin ? (
                      <VariableQuotasDropdown
                        numQuotas={quotas.length}
                        questions={questions}
                        variable={variable}
                      />
                    ) : undefined
                  }
                  title={variable.title}
                />
                <QuotasProgressHeader titleFirstColumn="Segment(s)" />
              </div>

              <div>
                {quotas.map((quota, quotaIdx) => {
                  return (
                    <div key={quotaIdx}>
                      <QuotaProgress
                        firstColumnContent={
                          <Requirements
                            logic="should"
                            requirements={quota.segments.map(
                              ({ title }) => title,
                            )}
                          />
                        }
                        numCompletes={quota.count}
                        numNeeded={quota.numberNeeded}
                        type={quota.type}
                      />
                      {quotaIdx !== quotas.length - 1 && (
                        <WordSeparator word="and" />
                      )}
                    </div>
                  );
                })}
              </div>
            </div>
          );
        })}
      </div>
    </TitledCard>
  );
};
