import {
  castArray,
  compact,
  find,
  flatten,
  forEach,
  isEmpty,
  map,
  some,
} from 'lodash-es';
import { FormikErrors } from 'formik';

import {
  Banner,
  BannerSliceOption,
  Export,
  ExportFilterModifier,
  MatrixOption,
  QUESTION_TYPE,
  Question,
  QuestionBase,
  QuestionConcept,
  QuestionLabel,
  QuestionOption,
  SpecificationType,
  Survey,
  SurveyVariable,
  SurveyVariableSegment,
  SurveyWave,
} from '../types/domainModels';
import {
  CreateExcelExportBody,
  IdeaPresenterCondition,
  MatrixCondition,
  MultipleChoiceCondition,
  NumberCondition,
  RankingScaleCondition,
  SaveBannerOption,
  SaveExportBannersBody,
  SegmentCondition,
  WaveCondition,
} from '../services/backend/exports';
import {
  getConceptOption,
  getOptionOption,
  getQuestionOption,
  getSurveyWaveOption,
} from './formOptions';
import {
  getSurveyVariableOption,
  getSurveyVariableSegmentOption,
} from './surveyVariables';
import { isIdeaPresenterQuestion } from './questions';
import { ReactSelectValue } from '../types/forms';
import { DATE_FORMATS, formatDate } from './dates';

export type HardcodedWavesQuestion = {
  type: 'Waves';
  waves: ReactSelectValue<SurveyWave>[];
};

export type ConstraintConcepts = {
  concepts:
    | ReactSelectValue<QuestionConcept>
    | ReactSelectValue<QuestionConcept>[];
};
export type ConstraintOptions = {
  options:
    | ReactSelectValue<QuestionOption>
    | ReactSelectValue<QuestionOption>[];
};
export type ConstraintWithNumber = {
  range: { end: number | ''; start: number | '' };
};
export type ConstraintWithNumberValidated = {
  range: { end: number; start: number };
};
export type ConstraintWithRange = {
  option: ReactSelectValue<QuestionOption> | null;
  range: { end: number | ''; start: number | '' };
};
export type ConstraintWithRangeValidated = {
  option: ReactSelectValue<QuestionOption>;
  range: { end: number; start: number };
};
export type ConstraintWithSegments = {
  segments:
    | ReactSelectValue<SurveyVariableSegment>
    | ReactSelectValue<SurveyVariableSegment>[];
};
export type ConstraintWithStatement = {
  statement: ReactSelectValue<QuestionOption> | null;
  options:
    | ReactSelectValue<QuestionLabel | MatrixOption>
    | ReactSelectValue<QuestionLabel | MatrixOption>[];
};
export type ConstraintWithStatementValidated = {
  statement: ReactSelectValue<QuestionOption>;
  options:
    | ReactSelectValue<QuestionLabel | MatrixOption>
    | ReactSelectValue<QuestionLabel | MatrixOption>[];
};
export type ConstraintWithWaves = {
  waves: ReactSelectValue<SurveyWave> | ReactSelectValue<SurveyWave>[];
};

type ExportConstraint =
  | ConstraintConcepts
  | ConstraintOptions
  | ConstraintWithNumber
  | ConstraintWithRange
  | ConstraintWithSegments
  | ConstraintWithStatement
  | ConstraintWithWaves;

type ExportConstraintValidated =
  | ConstraintConcepts
  | ConstraintOptions
  | ConstraintWithNumberValidated
  | ConstraintWithRangeValidated
  | ConstraintWithSegments
  | ConstraintWithStatementValidated
  | ConstraintWithWaves;

export interface ExportBanner {
  filters: ExportFilter[];
  name: string;
}

export interface ExportBannerValidated {
  filters: ExportFilterValidated[];
  name: string;
}

export interface ExportFilter {
  concept?: ReactSelectValue<QuestionConcept | null>;
  constraints: ExportConstraint[];
  modifier: ReactSelectValue<ExportFilterModifier> | null;
  question:
    | ReactSelectValue<Question>
    | ReactSelectValue<SurveyVariable>
    | ReactSelectValue<HardcodedWavesQuestion>
    | null;
}

export interface ExportFilterValidated {
  concept?: ReactSelectValue<QuestionConcept>;
  constraints: ExportConstraintValidated[];
  modifier: ReactSelectValue<ExportFilterModifier>;
  question: ReactSelectValue<Question>;
}

export interface ExportFormData {
  banners: ExportBanner[];
  baseFilter: ExportBanner | null;
  exportId: number | null;
  exportTitle: string;
  features: {
    stackQuestionLabels: boolean;
    statTesting: {
      confidenceLevel: ReactSelectValue<number>;
      enabled: boolean;
    };
  };
  questionsConfig: Record<number, ExportQuestionConfig>;
  waveIds: number[];
}

export type ExportFormDataValidated = Omit<
  ExportFormData,
  'banners' | 'baseFilter'
> & {
  banners: ExportBannerValidated[];
  baseFilter: ExportBannerValidated | null;
};

interface ExportQuestionConfig {
  average: boolean;
  base: ReactSelectValue<QuestionBase>;
  bottomXBox: {
    enabled: boolean;
    value: number | '';
  };
  include: boolean;
  questionId: number;
  rebase: boolean;
  topXBox: {
    enabled: boolean;
    value: number | '';
  };
}

export const QUESTION_BASE_EXPOSED_TO_OPTION = {
  label: 'Exposed to Option',
  value: 'exposedToOption',
} as const;
export const QUESTION_BASE_EXPOSED_TO_QUESTION = {
  label: 'Exposed to Question',
  value: 'exposedToQuestion',
} as const;
export const QUESTION_BASE_OPTIONS: ReactSelectValue<QuestionBase>[] = [
  QUESTION_BASE_EXPOSED_TO_OPTION,
  QUESTION_BASE_EXPOSED_TO_QUESTION,
];

export const STAT_TESTING_90: ReactSelectValue<number> = {
  label: '90% Confidence',
  value: 90,
};
export const STAT_TESTING_OPTIONS: ReactSelectValue<number>[] = [
  {
    label: '80% Confidence',
    value: 80,
  },
  STAT_TESTING_90,
  {
    label: '95% Confidence',
    value: 95,
  },
  {
    label: '99% Confidence',
    value: 99,
  },
];

export const MODIFIER_OPTION_EITHER: ReactSelectValue<ExportFilterModifier> = {
  label: 'either',
  value: 'should',
};
const MODIFIER_OPTION_EQUAL_TO: ReactSelectValue<ExportFilterModifier> = {
  label: 'equal to',
  value: 'is',
};
export const MODIFIER_OPTION_WITHIN: ReactSelectValue<ExportFilterModifier> = {
  label: 'within',
  value: 'should',
};
export const MODIFIER_OPTIONS_DEFAULT: ReactSelectValue<ExportFilterModifier>[] =
  [
    MODIFIER_OPTION_EQUAL_TO,
    MODIFIER_OPTION_EITHER,
    { label: 'not', value: 'isnt' },
  ];

export function canCalculateAverage(question: Question) {
  if (question.questionTypeId === QUESTION_TYPE.MATRIX) {
    return some(question.options[0]?.labels, (l) => !!l.weight);
  } else if (question.questionTypeId === QUESTION_TYPE.MULTIPLE_CHOICE) {
    return some(question.options, (o) => !!o.weight);
  }

  return [
    QUESTION_TYPE.GABOR_GRANGER,
    QUESTION_TYPE.NUMBER,
    QUESTION_TYPE.RANKING,
    QUESTION_TYPE.SCALE,
  ].includes(question.questionTypeId);
}

export function canCalculateTopBottomBox(question: Question) {
  return (
    !isIdeaPresenterQuestion(question) &&
    [
      QUESTION_TYPE.MATRIX,
      QUESTION_TYPE.MULTIPLE_CHOICE,
      QUESTION_TYPE.RANKING,
      QUESTION_TYPE.SCALE,
    ].includes(question.questionTypeId)
  );
}

export function canChangeBase(question: Question) {
  return (
    !isIdeaPresenterQuestion(question) &&
    [
      QUESTION_TYPE.MATRIX,
      QUESTION_TYPE.MULTIPLE_CHOICE,
      QUESTION_TYPE.RANKING,
      QUESTION_TYPE.SCALE,
    ].includes(question.questionTypeId)
  );
}

function getConditions({ filters }: { filters: ExportFilterValidated[] }) {
  return compact(
    filters.map((filter) => {
      const question = filter.question.value;

      if (isWaveFilterQuestion(question)) {
        return {
          data: {
            ids: filter.constraints.flatMap((constraint) => {
              if (isConstraintWithWaves(constraint)) {
                return castArray(constraint.waves).map((wave) => {
                  return wave.value.id;
                });
              }

              return [];
            }),
            logic: filter.modifier.value,
          } satisfies WaveCondition,
          type: 'wave' as const,
        };
      } else if (isSurveyVariableFilterQuestion(question)) {
        return {
          data: {
            ids: filter.constraints.flatMap((constraint) => {
              if (isConstraintWithSegments(constraint)) {
                return castArray(constraint.segments).map((segment) => {
                  return segment.value.id;
                });
              }

              return [];
            }),
            logic: filter.modifier.value,
          } satisfies SegmentCondition,
          type: 'segment' as const,
        };
      }
      if (isIdeaPresenterQuestion(question)) {
        return {
          data: {
            conceptId: filter.concept?.value?.id,
            id: question.id,
            response: filter.constraints.flatMap((constraint) => {
              if (isConstraintWithConcepts(constraint)) {
                return castArray(constraint.concepts).map((concept) => {
                  return concept.value.id;
                });
              }

              return [];
            }),
            logic: filter.modifier.value,
            type: 'ideaPresenter',
          } satisfies IdeaPresenterCondition,
          type: 'question' as const,
        };
      } else if (question.questionTypeId === QUESTION_TYPE.MATRIX) {
        const response: MatrixCondition['response'] = {};

        filter.constraints.forEach((constraint) => {
          if (isConstraintWithStatement(constraint)) {
            response[constraint.statement.value.id] = castArray(
              constraint.options,
            ).map((option) => `${option.value.id}`);
          }
        });

        return {
          data: {
            conceptId: filter.concept?.value?.id,
            id: question.id,
            response,
            logic: filter.modifier.value,
            type: 'matrixV2',
          } satisfies MatrixCondition,
          type: 'question' as const,
        };
      } else if (question.questionTypeId === QUESTION_TYPE.MULTIPLE_CHOICE) {
        return {
          data: {
            conceptId: filter.concept?.value?.id,
            id: question.id,
            response: filter.constraints.flatMap((constraint) => {
              if (isConstraintWithOptions(constraint)) {
                return castArray(constraint.options).map((option) => {
                  return option.value.id;
                });
              }

              return [];
            }),
            logic: filter.modifier.value,
            type: 'multipleChoice',
          } satisfies MultipleChoiceCondition,
          type: 'question' as const,
        };
      } else if (question.questionTypeId === QUESTION_TYPE.NUMBER) {
        const firstConstraint = filter.constraints[0];
        if (!isConstraintWithNumber(firstConstraint)) {
          return;
        }

        return {
          data: {
            conceptId: filter.concept?.value?.id,
            id: question.id,
            response: [firstConstraint.range.start, firstConstraint.range.end],
            logic: filter.modifier.value,
            type: 'number',
          } satisfies NumberCondition,
          type: 'question' as const,
        };
      } else if (
        question.questionTypeId === QUESTION_TYPE.RANKING ||
        question.questionTypeId === QUESTION_TYPE.SCALE
      ) {
        const response: RankingScaleCondition['response'] = {};

        filter.constraints.forEach((constraint) => {
          if (isConstraintWithRanges(constraint)) {
            response[constraint.option.value.id] = [
              [constraint.range.start, constraint.range.end],
            ];
          }
        });

        return {
          data: {
            conceptId: filter.concept?.value?.id,
            id: question.id,
            response,
            logic: filter.modifier.value,
            type:
              question.questionTypeId === QUESTION_TYPE.RANKING
                ? 'ranking'
                : 'scale',
          } satisfies RankingScaleCondition,
          type: 'question' as const,
        };
      }
    }),
  );
}

export function formDataToApiExcelExportData({
  formData,
}: {
  formData: ExportFormDataValidated;
}): CreateExcelExportBody {
  const calculatedMetrics: CreateExcelExportBody['calculatedMetrics'] = {};
  forEach(formData.questionsConfig, (config) => {
    const metrics: CreateExcelExportBody['calculatedMetrics'][string] = {};

    if (config.average) {
      metrics.average = config.average;
    }

    // "exposedToOption" is the default value for the base filter.
    // We don't need to include it in the API call since it will make the request size much
    // larger for surveys with many questions.
    if (config.base.value !== 'exposedToOption') {
      metrics.base = config.base.value;
    }

    if (config.bottomXBox.enabled && config.bottomXBox.value) {
      metrics.bottomBox = config.bottomXBox.value;
    }

    if (config.topXBox.enabled && config.topXBox.value) {
      metrics.topBox = config.topXBox.value;
    }

    if (!isEmpty(metrics)) {
      calculatedMetrics[config.questionId] = metrics;
    }
  });

  return {
    banners: formData.banners.map((banner) => {
      return {
        conditions: {
          groupLogic: 'or',
          groups: [getConditions({ filters: banner.filters })],
        },
        title: banner.name,
      };
    }),
    calculatedMetrics,
    filters: formData.baseFilter
      ? [
          {
            conditions: {
              groupLogic: 'or',
              groups: [getConditions({ filters: formData.baseFilter.filters })],
            },
            title: formData.baseFilter.name,
          },
        ]
      : [],
    meta: {
      exportedAt: formatDate(new Date(), {
        format: DATE_FORMATS.DATETIME,
      }),
      savedExportName: formData.exportTitle,
    },
    output: {
      excludeQuestionIds: compact(
        map(formData.questionsConfig, (config, questionId) => {
          return config.include ? null : Number(questionId);
        }),
      ),
      multiDimensionalQuestions: formData.features.stackQuestionLabels
        ? 'stack'
        : 'stretch',
    },
    statTesting: formData.features.statTesting.enabled
      ? {
          confidenceInterval:
            formData.features.statTesting.confidenceLevel.value,
        }
      : undefined,
  };
}

export function formDataToSaveExportData({
  bannerId,
  formData,
  survey,
}: {
  bannerId: number | null;
  formData: ExportFormData;
  survey: Survey;
}): SaveExportBannersBody {
  const banners = [
    ...(formData.baseFilter ? [{ ...formData.baseFilter, base: true }] : []),
    ...formData.banners.map((banner) => {
      return {
        ...banner,
        base: false,
      };
    }),
  ];

  return {
    averages: compact(
      map(formData.questionsConfig, ({ average, questionId }) => {
        if (average) {
          return questionId;
        }
      }),
    ),
    bannerId,
    bottomBox: compact(
      map(formData.questionsConfig, ({ bottomXBox, questionId }) => {
        return bottomXBox.enabled
          ? {
              questionId,
              value: bottomXBox.value === '' ? 2 : bottomXBox.value,
            }
          : null;
      }),
    ),
    categories: banners.map((banner) => {
      return {
        base: banner.base,
        categories: banner.filters.map((filter) => {
          const logicalModifier = filter.modifier?.value;

          if (isSurveyVariableFilterQuestion(filter.question?.value)) {
            return {
              attributes: [
                {
                  logicalModifier,
                  segments: flatten<SaveBannerOption>(
                    filter.constraints.map((constraint) => {
                      if (isConstraintWithSegments(constraint)) {
                        return castArray(constraint.segments).map((segment) => {
                          return {
                            id: segment.value.id,
                          };
                        });
                      }

                      return [];
                    }),
                  ),
                  percentage: 100,
                },
              ],
              variableId: filter.question?.value.id,
            };
          }

          if (isWaveFilterQuestion(filter.question?.value)) {
            return {
              attributes: [
                {
                  logicalModifier,
                  waves: flatten<SaveBannerOption>(
                    filter.constraints.map((constraint) => {
                      if (isConstraintWithWaves(constraint)) {
                        return castArray(constraint.waves).map((wave) => {
                          return {
                            id: wave.value.id,
                          };
                        });
                      }

                      return [];
                    }),
                  ),
                  percentage: 100,
                },
              ],
              useWaves: true,
            };
          }

          return {
            attributes: [
              {
                logicalModifier,
                options: flatten<SaveBannerOption>(
                  filter.constraints.map((constraint) => {
                    if (isConstraintWithConcepts(constraint)) {
                      return castArray(constraint.concepts).map((concept) => {
                        return { conceptId: concept.value };
                      });
                    } else if (isConstraintWithStatement(constraint)) {
                      return castArray(constraint.options).map((option) => {
                        return {
                          enumValue: constraint.statement?.value,
                          matrixOptionId: option.value.id,
                        };
                      });
                    } else if (isConstraintWithRanges(constraint)) {
                      return {
                        enumValue: constraint.option?.value.id,
                        highOption: constraint.range.end,
                        lowOption: constraint.range.start,
                      };
                    } else if (isConstraintWithNumber(constraint)) {
                      return {
                        highOption: constraint.range.end,
                        lowOption: constraint.range.start,
                      };
                    } else if (isConstraintWithOptions(constraint)) {
                      return castArray(constraint.options).map(
                        (option) => option.value,
                      );
                    }

                    return [];
                  }),
                ),
                percentage: 100,
              },
            ],
            question: filter.question?.value,
            concept: filter.concept?.value || null,
          };
        }),
        stat: !banner.base,
        title: banner.name || '',
      };
    }),
    confidenceLevel: formData.features.statTesting.enabled
      ? formData.features.statTesting.confidenceLevel.value
      : null,
    excludeQuestionIds: compact(
      map(formData.questionsConfig, ({ include, questionId }) => {
        if (!include) {
          return questionId;
        }
      }),
    ),
    exposedToQuestionIds: compact(
      map(formData.questionsConfig, ({ base, questionId }) => {
        if (base.value === 'exposedToQuestion') {
          return questionId;
        }
      }),
    ),
    rebaseIds: compact(
      map(formData.questionsConfig, ({ questionId, rebase }) => {
        return rebase ? questionId : null;
      }),
    ),
    stackComplexQuestions: formData.features.stackQuestionLabels,
    surveyId: survey.id,
    title: formData.exportTitle,
    topBox: compact(
      map(formData.questionsConfig, ({ questionId, topXBox }) => {
        return topXBox.enabled
          ? {
              questionId,
              value: topXBox.value === '' ? 2 : topXBox.value,
            }
          : null;
      }),
    ),
  };
}

export function getEmptyConstraintWithConcepts(): ConstraintConcepts {
  return { concepts: [] };
}

export function getEmptyConstraintWithNumber(): ConstraintWithNumber {
  return {
    range: { end: '', start: '' },
  };
}

export function getEmptyConstraintWithOptions(): ConstraintOptions {
  return { options: [] };
}

export function getEmptyConstraintWithRange(): ConstraintWithRange {
  return {
    option: null,
    range: { end: '', start: '' },
  };
}

export function getEmptyConstraintWithSegments(): ConstraintWithSegments {
  return { segments: [] };
}

export function getEmptyConstraintWithStatement(): ConstraintWithStatement {
  return {
    options: [],
    statement: null,
  };
}

export function getEmptyConstraintWithWaves(): ConstraintWithWaves {
  return { waves: [] };
}

export function getEmptyExportFilter(): ExportFilter {
  return {
    constraints: [],
    modifier: MODIFIER_OPTION_EQUAL_TO,
    question: null,
  };
}

function getExistingExportConstraints({
  apiConstraints,
  fullQuestion,
  fullVariable,
  surveyWaves,
  useWaves,
}: {
  apiConstraints: BannerSliceOption[];
  fullQuestion: Question | undefined;
  fullVariable: SurveyVariable | undefined;
  surveyWaves: SurveyWave[];
  useWaves: boolean;
}): ExportConstraint[] {
  if (fullVariable) {
    return [
      {
        segments: compact(
          apiConstraints.map(({ segmentId }) => {
            const segment = fullVariable.segments.find(({ id }) => {
              return id === segmentId;
            });

            return segment
              ? getSurveyVariableSegmentOption(segment)
              : undefined;
          }),
        ),
      },
    ];
  }

  if (useWaves) {
    return [
      {
        waves: compact(
          apiConstraints.map(({ waveId }) => {
            const wave = surveyWaves.find(({ id }) => {
              return id === waveId;
            });

            return wave ? getSurveyWaveOption(wave) : undefined;
          }),
        ),
      },
    ];
  }

  if (!fullQuestion) {
    return [];
  }

  if (isIdeaPresenterQuestion(fullQuestion)) {
    return [
      {
        concepts: compact(
          apiConstraints.map((constraint) => {
            const concept = find(
              fullQuestion?.concepts,
              ({ id }) => id === constraint.conceptId,
            );

            return concept ? getConceptOption({ concept }) : undefined;
          }),
        ),
      },
    ];
  } else if (fullQuestion.questionTypeId === QUESTION_TYPE.MULTIPLE_CHOICE) {
    return [
      {
        options: compact(
          apiConstraints.map((constraint) => {
            const option = find(
              fullQuestion.options,
              ({ id }) => id === constraint.optionId,
            );

            return option ? getOptionOption({ option }) : undefined;
          }),
        ),
      },
    ];
  } else if (
    fullQuestion.questionTypeId === QUESTION_TYPE.RANKING ||
    fullQuestion.questionTypeId === QUESTION_TYPE.SCALE
  ) {
    return compact(
      apiConstraints.map((constraint) => {
        const option = find(
          fullQuestion.options,
          ({ id }) => id === constraint.optionId,
        );

        return option
          ? {
              option: getOptionOption({ option }),
              range: {
                end: constraint.highOption,
                start: constraint.lowOption,
              },
            }
          : undefined;
      }),
    );
  } else if (fullQuestion.questionTypeId === QUESTION_TYPE.MATRIX) {
    // Note that the API calls a UI statement an option. So that's why this map may seem backwards.
    const statementOptionsMap = new Map<
      QuestionOption,
      ReactSelectValue<QuestionLabel | MatrixOption>[]
    >();

    // We want to group our statements with their corresponding options. So we first build up a mapping of statement
    // to its options.
    apiConstraints.forEach((constraint) => {
      const statement = find(
        fullQuestion.options,
        ({ id }) => id === constraint.optionId,
      );

      const matrixOption = find(
        fullQuestion.matrixOptions,
        ({ id }) => id === constraint.matrixOptionId,
      );
      const formOption = matrixOption
        ? { label: matrixOption.title, value: matrixOption }
        : undefined;

      if (!statement || !formOption) {
        return;
      }

      const statementOptions = statementOptionsMap.get(statement);
      if (statementOptions) {
        statementOptionsMap.set(statement, [...statementOptions, formOption]);
      } else {
        statementOptionsMap.set(statement, [formOption]);
      }
    });

    const constraints: ConstraintWithStatement[] = [];
    statementOptionsMap.forEach((options, statement) => {
      constraints.push({
        statement: getOptionOption({ option: statement }),
        options,
      });
    });

    return constraints;
  } else if (fullQuestion.questionTypeId === QUESTION_TYPE.NUMBER) {
    return apiConstraints.map((constraint) => {
      return {
        range: {
          end: constraint.highOption,
          start: constraint.lowOption,
        },
      };
    });
  }

  return [];
}

export function getExportFilename({
  surveyExport,
  surveyTitle,
}: {
  surveyExport: Export;
  surveyTitle: string;
}): string {
  const { createdAt, crosstabName, exportType } = surveyExport;

  if (exportType === 'SPSS') {
    return `${surveyTitle} SPSS.sav`;
  }

  const formattedExportDate = formatDate(createdAt, {
    format: DATE_FORMATS.EXPORT_DATE,
  });

  if (exportType === 'CROSSTAB') {
    const sanitizedExportName = crosstabName
      ? crosstabName.replace(/\//g, '_')
      : '';

    return `${surveyTitle}_${sanitizedExportName ? `${sanitizedExportName}_` : ''}${formattedExportDate}.xlsx`;
  } else if (exportType === 'RAW_DATA') {
    return `${surveyTitle}_Raw Data_${formattedExportDate}.xlsx`;
  }

  throw new Error(`Unknown export type: ${exportType}`);
}

export function getFormDataFromExistingExport({
  allQuestions,
  configurableQuestions,
  existingExport,
  monadicConcepts,
  surveyVariables,
  surveyWaves,
}: {
  allQuestions: ReactSelectValue<Question>[];
  configurableQuestions: ReactSelectValue<Question>[];
  existingExport: Banner;
  monadicConcepts: ReactSelectValue<QuestionConcept>[];
  surveyVariables: SurveyVariable[];
  surveyWaves: SurveyWave[];
}): Partial<ExportFormData> {
  const banners: ExportFormData['banners'] = [];
  let baseFilter: ExportFormData['baseFilter'] = null;

  existingExport.surveyBannerSlices.forEach(
    ({ base, surveyBannerSliceCategories, title }) => {
      const exportFilters = surveyBannerSliceCategories.map(
        ({
          concept,
          question,
          surveyBannerSliceCategoryAttributes,
          useWaves,
          variableId,
        }) => {
          const { logicalModifier, surveyBannerSliceCategoryAttributeOptions } =
            surveyBannerSliceCategoryAttributes[0];
          const fullQuestion = question
            ? allQuestions.find(({ value }) => {
                return question.id === value.id;
              })?.value
            : undefined;
          const fullConcept = concept
            ? monadicConcepts.find(({ value }) => {
                return concept.id === value.id;
              })?.value
            : undefined;
          const fullVariable = variableId
            ? surveyVariables.find(({ id }) => {
                return id === variableId;
              })
            : undefined;

          let questionOption:
            | ReactSelectValue<Question>
            | ReactSelectValue<SurveyVariable>
            | ReactSelectValue<HardcodedWavesQuestion>
            | null = null;
          if (fullQuestion) {
            questionOption = getQuestionOption({ question: fullQuestion });
          } else if (fullVariable) {
            questionOption = getSurveyVariableOption(fullVariable);
          } else if (useWaves) {
            questionOption = {
              label: 'Waves',
              value: {
                waves: surveyWaves.map((wave) => getSurveyWaveOption(wave)),
                type: 'Waves',
              },
            };
          }

          return {
            constraints: getExistingExportConstraints({
              apiConstraints: surveyBannerSliceCategoryAttributeOptions,
              fullQuestion,
              fullVariable,
              surveyWaves,
              useWaves,
            }),
            modifier:
              MODIFIER_OPTIONS_DEFAULT.find(({ value }) => {
                return value === logicalModifier;
              }) ?? null,
            question: questionOption,
            concept: fullConcept
              ? { label: fullConcept?.description, value: fullConcept }
              : { label: 'Any concept', value: null },
          };
        },
      );

      if (exportFilters.length === 0) {
        return;
      }

      if (base) {
        baseFilter = { filters: exportFilters, name: title };
      } else {
        banners.push({ filters: exportFilters, name: title });
      }
    },
  );

  const statTestingOption = STAT_TESTING_OPTIONS.find(
    ({ value }) => value === existingExport.confidenceLevel,
  );

  const questionsConfig: Record<number, ExportQuestionConfig> = {};
  configurableQuestions.forEach(({ value }) => {
    const questionId = value.id;

    const bottomBoxConfig = existingExport.questionSpecifications?.find(
      (qs) =>
        qs.questionId === questionId && qs.type === SpecificationType.bottomBox,
    );
    const topBoxConfig = existingExport.questionSpecifications?.find(
      (qs) =>
        qs.questionId === questionId && qs.type === SpecificationType.topBox,
    );
    const averageConfig = existingExport.questionSpecifications?.find(
      (qs) =>
        qs.questionId === questionId && qs.type === SpecificationType.average,
    );

    questionsConfig[questionId] = {
      average: !!averageConfig,
      base: existingExport.exposedToQuestionIds?.includes(questionId)
        ? QUESTION_BASE_EXPOSED_TO_QUESTION
        : QUESTION_BASE_EXPOSED_TO_OPTION,
      bottomXBox: {
        enabled:
          existingExport.bottomBoxIds?.includes(questionId) ||
          !!bottomBoxConfig,
        value: existingExport.bottomBoxValue ?? bottomBoxConfig?.value ?? '',
      },
      include: !existingExport.excludeQuestionIds.includes(questionId),
      questionId,
      rebase: existingExport.rebaseIds.includes(questionId),
      topXBox: {
        enabled:
          existingExport.topBoxIds?.includes(questionId) || !!topBoxConfig,
        value: existingExport.topBoxValue ?? topBoxConfig?.value ?? '',
      },
    };
  });

  return {
    banners,
    baseFilter,
    exportId: existingExport.id,
    exportTitle: existingExport.title,
    features: {
      stackQuestionLabels: existingExport.stackComplexQuestions,
      statTesting: {
        confidenceLevel: statTestingOption ?? STAT_TESTING_90,
        enabled: !!existingExport.confidenceLevel,
      },
    },
    questionsConfig,
  };
}

export function getInitialExportFormData({
  questions,
}: {
  questions: ReactSelectValue<Question>[];
}): ExportFormDataValidated {
  const questionsConfig: Record<number, ExportQuestionConfig> = {};
  questions.forEach(({ value }) => {
    questionsConfig[value.id] = {
      average: false,
      base: QUESTION_BASE_EXPOSED_TO_OPTION,
      bottomXBox: { enabled: false, value: '' },
      include: value.isActive,
      questionId: value.id,
      rebase: true,
      topXBox: { enabled: false, value: '' },
    };
  });

  return {
    banners: [],
    baseFilter: null,
    exportId: null,
    exportTitle: '',
    features: {
      stackQuestionLabels: true,
      statTesting: {
        confidenceLevel: STAT_TESTING_90,
        enabled: false,
      },
    },
    questionsConfig,
    waveIds: [],
  };
}

export function isConstraintWithConcepts(
  constraint: ExportConstraint,
): constraint is ConstraintConcepts {
  return (constraint as ConstraintConcepts).concepts !== undefined;
}

export function isConstraintWithNumber(
  constraint: ExportConstraint,
): constraint is ConstraintWithNumber {
  return (
    (constraint as ConstraintWithRange).option === undefined &&
    (constraint as ConstraintWithNumber).range !== undefined
  );
}

export function isConstraintWithOptions(
  constraint: ExportConstraint,
): constraint is ConstraintOptions {
  return (constraint as ConstraintOptions).options !== undefined;
}

export function isConstraintWithRanges(
  constraint: ExportConstraint,
): constraint is ConstraintWithRange {
  // We need to check both "option" and "range" because number constraints have "range" but not "option".
  return (
    (constraint as ConstraintWithRange).option !== undefined &&
    (constraint as ConstraintWithRange).range !== undefined
  );
}

export function isConstraintWithSegments(
  constraint: ExportConstraint,
): constraint is ConstraintWithSegments {
  return (constraint as ConstraintWithSegments).segments !== undefined;
}

export function isConstraintWithStatement(
  constraint: ExportConstraint,
): constraint is ConstraintWithStatement {
  return (constraint as ConstraintWithStatement).statement !== undefined;
}

export function isConstraintWithWaves(
  constraint: ExportConstraint,
): constraint is ConstraintWithWaves {
  return (constraint as ConstraintWithWaves).waves !== undefined;
}

export function isSurveyVariableFilterQuestion(
  question: Question | SurveyVariable | HardcodedWavesQuestion | undefined,
): question is SurveyVariable {
  return (question as SurveyVariable)?.segments !== undefined;
}

export function isWaveFilterQuestion(
  value: Question | SurveyVariable | HardcodedWavesQuestion | undefined,
): value is HardcodedWavesQuestion {
  return (value as HardcodedWavesQuestion)?.type === 'Waves';
}

function validateExportBanners(
  banners: ExportBanner[],
): FormikErrors<ExportBanner>[] | undefined {
  const bannersErrors: FormikErrors<ExportBanner>[] = [];

  banners.forEach((banner) => {
    const bannerErrors: FormikErrors<ExportBanner> = {};
    const filtersErrors: FormikErrors<ExportFilter>[] = [];

    if (!banner.name) {
      bannerErrors.name = 'Please provide a name.';
    }

    banner.filters.forEach((filter) => {
      const constraintErrors: FormikErrors<ExportConstraint>[] = [];
      const filterErrors: FormikErrors<ExportFilter> = {};

      filter.constraints.forEach((constraint) => {
        if (isConstraintWithStatement(constraint)) {
          const errors: FormikErrors<ConstraintWithStatement> = {};

          if (!constraint.statement) {
            errors.statement = 'Please select a statement.';
          }

          if (castArray(constraint.options).length === 0) {
            // Formik doesn't like this specification for the error but it's perfectly acceptable.
            errors.options = 'Please choose options.' as FormikErrors<
              ReactSelectValue<QuestionLabel>
            >;
          }

          constraintErrors.push(errors);
        } else if (isConstraintWithRanges(constraint)) {
          const errors: FormikErrors<ConstraintWithRange> = {};

          if (!constraint.option) {
            errors.option = 'Please select an option.';
          }

          if (constraint.range.end === '' || constraint.range.start === '') {
            const rangeErrors: FormikErrors<ConstraintWithRange['range']> = {};

            if (constraint.range.end === '') {
              rangeErrors.end = 'Required';
            }

            if (constraint.range.start === '') {
              rangeErrors.start = 'Required';
            }

            errors.range = rangeErrors;
          }

          constraintErrors.push(errors);
        } else if (isConstraintWithNumber(constraint)) {
          const errors: FormikErrors<ConstraintWithNumber> = {};

          if (constraint.range.end === '' || constraint.range.start === '') {
            const rangeErrors: FormikErrors<ConstraintWithRange['range']> = {};

            if (constraint.range.end === '') {
              rangeErrors.end = 'Required';
            }

            if (constraint.range.start === '') {
              rangeErrors.start = 'Required';
            }

            errors.range = rangeErrors;
          }

          constraintErrors.push(errors);
        } else if (isConstraintWithConcepts(constraint)) {
          const errors: FormikErrors<ConstraintConcepts> = {};

          if (castArray(constraint.concepts).length === 0) {
            // Formik doesn't like this specification for the error but it's perfectly acceptable.
            errors.concepts = 'Required' as FormikErrors<
              ReactSelectValue<QuestionLabel>
            >;
          }

          constraintErrors.push(errors);
        } else if (isConstraintWithSegments(constraint)) {
          const errors: FormikErrors<ConstraintWithSegments> = {};

          if (castArray(constraint.segments).length === 0) {
            // Formik doesn't like this specification for the error but it's perfectly acceptable.
            errors.segments = 'Required' as FormikErrors<
              ReactSelectValue<QuestionLabel>
            >;
          }

          constraintErrors.push(errors);
        } else if (isConstraintWithWaves(constraint)) {
          const errors: FormikErrors<ConstraintWithWaves> = {};

          if (castArray(constraint.waves).length === 0) {
            // Formik doesn't like this specification for the error but it's perfectly acceptable.
            errors.waves = 'Required' as FormikErrors<
              ReactSelectValue<QuestionLabel>
            >;
          }

          constraintErrors.push(errors);
        } else {
          const errors: FormikErrors<ConstraintOptions> = {};

          if (castArray(constraint.options).length === 0) {
            // Formik doesn't like this specification for the error but it's perfectly acceptable.
            errors.options = 'Required' as FormikErrors<
              ReactSelectValue<QuestionLabel>
            >;
          }

          constraintErrors.push(errors);
        }
      });

      if (some(constraintErrors, (errors) => !isEmpty(errors))) {
        filterErrors.constraints = constraintErrors;
      }

      if (!filter.modifier) {
        filterErrors.modifier = 'Please choose a modifier.';
      }

      if (!filter.question) {
        filterErrors.question = 'Please choose a question.';
      }

      filtersErrors.push(filterErrors);
    });

    if (some(filtersErrors, (errors) => !isEmpty(errors))) {
      bannerErrors.filters = filtersErrors;
    }

    bannersErrors.push(bannerErrors);
  });

  return some(bannersErrors, (errors) => !isEmpty(errors))
    ? bannersErrors
    : undefined;
}

export function validateExportData(
  formData: ExportFormData,
): FormikErrors<ExportFormData> {
  const errors: FormikErrors<ExportFormData> = {};

  if (formData.baseFilter) {
    const baseFilterErrors = validateExportBanners([formData.baseFilter]);

    // Formik doesn't like this specification for the error but it's perfectly acceptable.
    errors.baseFilter = baseFilterErrors
      ? (baseFilterErrors[0] as string)
      : undefined;
  }

  errors.banners = validateExportBanners(formData.banners);

  return some(errors) ? errors : {};
}
