import { cloneDeep } from 'lodash-es';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { FieldArray, FieldHelperProps, useField } from 'formik';

import { useModal } from '../../../hooks/modals';
import {
  apiOptionToFormOption,
  getVariableReferencesForMatrixOption,
  getOtherQuestionReferencesForMatrixOption,
} from '../../../util/questions';
import { OPTION_FEATURES } from '../../../util/options';
import { Question, SurveyVariable } from '../../../types/domainModels';
import { QuestionFormData, QuestionFormOption } from '../../../types/forms';

import AddButton from '../../common/forms/AddButton';
import DisplayXOfY from '../questionFeatures/DisplayXOfY';
import FormCheckbox from '../../common/forms/FormCheckbox';
import FormInput from '../../common/forms/FormInput';
import Icon from 'components/common/Icon';
import IconBackground from '../../common/icons/IconBackground';
import Popover from '../../common/Popover';
import XButton from '../../common/forms/XButton';
import Tooltip from '../../common/Tooltip';
import ConceptField from '../ConceptField';
import OptionFeatureLabel from '../OptionFeatureLabel';
import PasteOptionsPopover from '../PasteOptionsPopover';
import PreventDeleteMatrixOptionModal from '../PreventDeleteMatrixOptionModal';
import QuestionOptions from '../QuestionOptions';
import CarryForward from '../questionFeatures/CarryForward';
import DisplayLogic from '../questionFeatures/DisplayLogic';
import DisplayOptionDescription from '../questionFeatures/DisplayOptionDescription';
import DragIcon from 'components/common/icons/DragIcon';
import MultipleOptionSelections from '../questionFeatures/MultipleOptionSelections';
import OpenMatrix from '../questionFeatures/OpenMatrix';
import PipeConcept from '../questionFeatures/PipeConcept';
import RandomizeMatrixOptions from '../questionFeatures/RandomizeMatrixOptions';
import RandomizeStatements from '../questionFeatures/RandomizeStatements';
import RangeConstraint from '../questionFeatures/RangeConstraint';
import ViewConcept from '../questionFeatures/ViewConcept';
import SurveyEditRow from '../SurveyEditRow';
import SurveyEditRowLeftSide from '../SurveyEditRowLeftSide';

const Matrix = ({
  concepts = [],
  pipeConcept = false,
  question,
  questions,
  variables,
}: {
  concepts?: QuestionFormOption[];
  pipeConcept?: boolean;
  question: Question | undefined;
  questions: Question[];
  variables: SurveyVariable[];
}) => {
  const [{ value: features }] =
    useField<QuestionFormData['features']>('features');

  return (
    <>
      <SurveyEditRow
        layout={concepts.length > 0 ? 'column' : 'row'}
        subtitle="(optional)"
        title="Concept Image or Video"
        tooltip="Upload either image or video files. Suggested formats are jpeg/png for images or mp4 for video."
      >
        <ConceptField
          concepts={concepts}
          disabled={pipeConcept}
          question={question}
          questions={questions}
          upperLimit={1}
        />
      </SurveyEditRow>

      <div className="p-6 border-b border-gray-300">
        <SurveyEditRowLeftSide
          title={`Statements ${
            features.openMatrix.enabled
              ? features.openMatrix.inverted
                ? '(columns)'
                : '(rows)'
              : ''
          }`}
        />
        <QuestionOptions
          fieldName="labels"
          question={question}
          questions={questions}
          variables={variables}
        />
      </div>

      <div className="p-6 border-b border-gray-300">
        <SurveyEditRowLeftSide
          title={`Options ${
            features.openMatrix.enabled
              ? features.openMatrix.inverted
                ? '(rows)'
                : '(columns)'
              : ''
          }`}
        />
        <MatrixOptions questions={questions} variables={variables} />
      </div>

      <div className="p-6 border-b border-gray-300">
        <SurveyEditRowLeftSide title="Option Features" />
        <div className="mx-4 mt-4 space-y-4">
          <RandomizeStatements />
          <RandomizeMatrixOptions
            disabled={!!question?.features?.find((f) => f.code === 'RMO')}
          />
          <MultipleOptionSelections />
          <OpenMatrix />
          <DisplayXOfY optionsFieldName="labels" />
          <DisplayOptionDescription />
          <CarryForward
            formFieldName="labels"
            question={question}
            questions={questions}
          />
        </div>
      </div>

      <div className="p-6">
        <SurveyEditRowLeftSide title="Question Features" />
        <div className="mx-4 mt-4 space-y-4">
          <PipeConcept
            concepts={concepts}
            question={question}
            questions={questions}
          />
          <ViewConcept />
          <DisplayLogic question={question} questions={questions} />
          <RangeConstraint constraintLabel="Numeric Free Text" />
        </div>
      </div>
    </>
  );
};

export default Matrix;

const MatrixOptions = ({
  questions,
  variables,
}: {
  questions: Question[];
  variables: SurveyVariable[];
}) => {
  const [{ value: options }, , optionsHelpers] =
    useField<QuestionFormData['options']>('options');

  return (
    <>
      <FieldArray
        name="options"
        render={(arrayHelpers) => {
          return (
            <>
              <DragDropContext
                onDragEnd={({ destination, source }) => {
                  if (!destination || source.index === destination.index) {
                    return;
                  }

                  arrayHelpers.move(source.index, destination.index);
                }}
              >
                <Droppable droppableId="droppable">
                  {(provided) => (
                    <div {...provided.droppableProps} ref={provided.innerRef}>
                      {options.map((_option, index) => {
                        return (
                          <MatrixOption
                            key={index}
                            index={index}
                            onClickRemove={() => {
                              if (options.length > 1) {
                                arrayHelpers.remove(index);
                              } else {
                                arrayHelpers.replace(
                                  index,
                                  apiOptionToFormOption(),
                                );
                              }
                            }}
                            questions={questions}
                            variables={variables}
                          />
                        );
                      })}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
              <div className="flex mt-2 space-x-4">
                <AddButton
                  label="Add Option"
                  onClick={() => {
                    arrayHelpers.push(apiOptionToFormOption());
                  }}
                />
                <PasteOptionsPopover
                  onAddOptions={(optionsToAdd, { shouldReplace }) => {
                    const newOptions = optionsToAdd.map((option) => {
                      return apiOptionToFormOption({
                        option: { title: option },
                      });
                    });

                    optionsHelpers.setValue(
                      shouldReplace ? newOptions : [...options, ...newOptions],
                    );
                  }}
                />
                <ReverseMatrixOptions
                  options={options}
                  optionsHelpers={optionsHelpers}
                />
              </div>
            </>
          );
        }}
      />
    </>
  );
};

const ReverseMatrixOptions = ({
  options,
  optionsHelpers,
}: {
  options: QuestionFormOption[];
  optionsHelpers: FieldHelperProps<QuestionFormOption[]>;
}): JSX.Element => {
  return (
    <AddButton
      label={`Reverse Options`}
      onClick={() => {
        // For some reason using array.prototype.reverse() doesn't trigger change in typeorm,
        // so we'll reverse them manually
        const optionsLength = options.length;
        let reversedOptions: QuestionFormOption[] = [];
        for (let i = 0; i < optionsLength; i = i + 1) {
          reversedOptions = [options[i], ...reversedOptions];
        }

        optionsHelpers.setValue(reversedOptions);
      }}
    />
  );
};

const MatrixOption = ({
  index,
  onClickRemove,
  questions,
  variables,
}: {
  index: number;
  onClickRemove(): void;
  questions: Question[];
  variables: SurveyVariable[];
}) => {
  const optionFieldName = `options.${index}`;
  const [{ value: optionFeatures }, , optionFeatureHelpers] = useField<
    QuestionFormOption['features']
  >(`${optionFieldName}.features`);
  const [{ value: optionType }] =
    useField<QuestionFormData['optionType']>('optionType');
  const [{ value: questionFeatures }] =
    useField<QuestionFormData['features']>('features');
  const [{ value: questionType }] =
    useField<QuestionFormData['questionType']>('questionType');
  const [, , weightHelper] = useField<QuestionFormOption['weight']>(
    `${optionFieldName}.weight`,
  );
  const [{ value: matrixOption }] = useField<QuestionFormOption>(
    `${optionFieldName}`,
  );
  const [{ value: options }] = useField<QuestionFormOption[]>('options');

  const {
    isOpen: isPreventDeleteModalOpen,
    onCloseModal,
    setIsOpen: setIsPreventDeleteModalOpen,
  } = useModal();

  const availableOptionFeatures = OPTION_FEATURES.filter(({ available }) => {
    return available({
      features: questionFeatures,
      isLabel: false,
      isMatrixOption: true,
      optionType: optionType?.value,
      questionType,
    });
  });

  return (
    <Draggable draggableId={`matrix-option-${index}`} index={index}>
      {(provided) => {
        return (
          <div
            ref={provided.innerRef}
            className="flex items-center"
            {...provided.draggableProps}
          >
            <div
              {...provided.dragHandleProps}
              className="flex flex-col items-center justify-center h-full"
              tabIndex={-1}
            >
              <div className="w-4 h-4 text-gray-500 visible">
                <DragIcon />
              </div>
            </div>
            <div className="flex p-2 grow">
              <div className="flex items-start w-full space-x-4">
                <div className="flex-grow">
                  <FormInput
                    name={`${optionFieldName}.value`}
                    size="md"
                    type="text"
                  />
                  <div>
                    <div className="space-x-2">
                      {availableOptionFeatures.map(
                        ({
                          featureName,
                          inverseDisplay = false,
                          label,
                          labelDisplay,
                        }) => {
                          const isEnabled = optionFeatures?.[featureName];
                          const showLabel = inverseDisplay
                            ? !isEnabled
                            : isEnabled;

                          if (showLabel) {
                            return (
                              <OptionFeatureLabel
                                key={featureName}
                                label={labelDisplay || label}
                                onClickRemove={() => {
                                  optionFeatureHelpers.setValue({
                                    ...optionFeatures,
                                    [featureName]: inverseDisplay,
                                  });
                                }}
                              />
                            );
                          }

                          return null;
                        },
                      )}
                      {optionFeatures.useWeight && (
                        <OptionFeatureLabel
                          label="weight"
                          onClickRemove={() => {
                            const newOptionFeatures = cloneDeep(optionFeatures);
                            newOptionFeatures.useWeight = false;
                            weightHelper.setValue(null);

                            optionFeatureHelpers.setValue(newOptionFeatures);
                          }}
                        />
                      )}
                    </div>
                  </div>
                </div>
                {optionFeatures.useWeight && (
                  <div className="w-12">
                    <FormInput
                      name={`${optionFieldName}.weight`}
                      placeholder="Weight (#)"
                      size="md"
                      type="number"
                    />
                  </div>
                )}
                <div className="flex items-center mt-2 space-x-2">
                  <Popover
                    name={`matrix-option-${index}`}
                    trigger={(triggerProps) => (
                      <div {...triggerProps}>
                        <IconBackground size="small" title="Settings">
                          <div className="w-3 h-3">
                            <Icon id="settings-01" />
                          </div>
                        </IconBackground>
                      </div>
                    )}
                  >
                    <div className="w-44 p-4 space-y-2 text-sm">
                      {availableOptionFeatures.map(
                        ({ featureName, label, disabled }) => {
                          return (
                            <FormCheckbox
                              key={featureName}
                              checkboxLabel={label} // optional return param
                              disabled={disabled({
                                isMatrixOption: true,
                                optionFeatures,
                                options,
                              })}
                              name={`${optionFieldName}.features.${featureName}`}
                            />
                          );
                        },
                      )}
                      <div className="flex">
                        <FormCheckbox
                          checkboxLabel={
                            <div className="flex items-center">
                              <span className="mr-2">Use weight</span>
                            </div>
                          }
                          name={`${optionFieldName}.features.useWeight`}
                          onChange={(value) => {
                            if (!value) {
                              weightHelper.setValue(null);
                            }
                          }}
                        />

                        <div className="ml-2 h-full">
                          <Tooltip>
                            Weights will be applied when calculating averages
                            for the question in crosstab exports.
                          </Tooltip>
                        </div>
                      </div>
                    </div>
                  </Popover>
                  {onClickRemove && (
                    <XButton
                      onClick={() => {
                        const questionReferences =
                          getOtherQuestionReferencesForMatrixOption({
                            matrixOption,
                            questions,
                          });
                        const variableReferences =
                          getVariableReferencesForMatrixOption({
                            matrixOption,
                            variables,
                          });
                        const hasOtherReferences =
                          questionReferences.length > 0 ||
                          variableReferences.length > 0;

                        if (hasOtherReferences) {
                          setIsPreventDeleteModalOpen(true);
                        } else {
                          onClickRemove();
                        }
                      }}
                      title="Remove"
                    />
                  )}
                </div>
              </div>

              {isPreventDeleteModalOpen && (
                <PreventDeleteMatrixOptionModal
                  matrixOption={matrixOption}
                  onCloseModal={onCloseModal}
                  questions={questions}
                  variables={variables}
                />
              )}
            </div>
          </div>
        );
      }}
    </Draggable>
  );
};
