import { assign, fromPromise, setup } from 'xstate';

import { DataUrl } from 'types/domainModels';
import { uploadMedia } from 'services/cloudinary/media';

export const mediaUploadMachine = setup({
  types: {} as {
    context: { mediaData: string };
    input: { mediaData: string };
    events: { data: string; type: 'MEDIA_DATA_RECEIVED' };
  },

  actions: {
    logMediaUploadError() {
      throw new Error('Unimplemented');
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    processUploadedMedia(_, params: { mediaData: DataUrl }) {
      throw new Error('Unimplemented');
    },
    storeMediaData: assign({
      mediaData: (_, params: { data: string }) => params.data,
    }),
  },
  actors: {
    uploadMedia: fromPromise(
      async ({ input }: { input: { mediaData: string } }) => {
        return uploadMedia({ mediaData: input.mediaData });
      },
    ),
  },
  guards: {
    hasMediaData: ({ context }) => !!context.mediaData,
    isMediaDataDifferent: ({ context, event }) =>
      context.mediaData !== event.data,
  },
}).createMachine({
  id: 'mediaUpload',
  context: ({ input }) => ({ mediaData: input.mediaData }),
  initial: 'checkForMediaData',
  states: {
    checkForMediaData: {
      always: [
        { guard: 'hasMediaData', target: 'uploading' },
        { target: 'idle' },
      ],
    },
    idle: {
      on: {
        MEDIA_DATA_RECEIVED: {
          actions: {
            params: ({ event }) => {
              return { data: event.data };
            },
            type: 'storeMediaData',
          },
          guard: 'isMediaDataDifferent',
          target: 'uploading',
        },
      },
    },
    uploading: {
      invoke: {
        src: 'uploadMedia',
        input: ({ context }) => ({ mediaData: context.mediaData }),
        onDone: {
          actions: [
            {
              type: 'processUploadedMedia',
              params: ({ event }) => ({ mediaData: event.output }),
            },
          ],
          target: 'idle',
        },
        onError: {
          actions: [{ type: 'logMediaUploadError' }],
          target: 'idle',
        },
      },
    },
  },
});
