import { filter, find, sortBy } from 'lodash-es';
import { ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';

import { getLiveLink, getTestLinks } from 'util/surveys';
import { isNaturalFallout } from '../../util/audience';
import { LOGICAL_MODIFIER_OPTIONS_MAP } from '../../util/demographics';
import { Question, Survey, SurveyVariable } from '../../types/domainModels';
import { useHasRole } from 'hooks/users';

import Card from '../common/Card';
import CopyToClipboard from 'components/common/CopyToClipboard';
import Hyperlink from '../common/Hyperlink';
import Icon from 'components/common/Icon';
import IconBackground from '../common/icons/IconBackground';

interface SurveyQuota {
  definitions: ReactNode[];
  questionTitle: string;
}

function getSurveyQuotasFromAudience(survey: Survey): SurveyQuota[] {
  if (isNaturalFallout(survey)) {
    return [];
  }

  const surveyQuotas: SurveyQuota[] = [];
  const orderedDemographics = sortBy(
    survey.audience.audienceSlices,
    'audienceSliceCategories.0.question.sort',
  );

  orderedDemographics.forEach(({ audienceSliceCategories }) => {
    const question = audienceSliceCategories[0].question;

    const quota: SurveyQuota = {
      definitions: [],
      questionTitle: `D${question.sort}) ${question.title}`,
    };

    audienceSliceCategories.forEach(
      ({
        audienceSliceCategoryAttributes,
        logicalModifier,
        percentage,
        question,
      }) => {
        const modifier = find(
          // TODO: Address compilation error
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-expect-error
          LOGICAL_MODIFIER_OPTIONS_MAP[question.questionTypeId],
          { value: logicalModifier },
        );
        const optionsStr = audienceSliceCategoryAttributes
          .map(({ audienceAttribute }) => {
            if (audienceAttribute.enumValue) {
              return audienceAttribute.enumValue.title;
            } else if (audienceAttribute.numberRange) {
              return `${audienceAttribute.numberRange.start}-${audienceAttribute.numberRange.end}`;
            }

            return '';
          })
          .join(
            logicalModifier === 'should' || logicalModifier === 'shouldnt'
              ? ' or '
              : ' and ',
          );

        quota.definitions.push(
          <span>
            {Number(percentage) * 100}%{' '}
            <strong className="font-bold">{modifier.label}</strong> {optionsStr}
          </span>,
        );
      },
    );

    surveyQuotas.push(quota);
  });

  return surveyQuotas;
}

function getSurveyQuotasFromQuestions(questions: Question[]): SurveyQuota[] {
  const surveyQuotas: SurveyQuota[] = [];

  const questionsWithQuotas = filter(questions, ({ questionQuotas }) => {
    return !!(questionQuotas && questionQuotas.length > 0);
  });

  questionsWithQuotas.forEach(
    ({ options: questionOptions, quotas = [], sort, title }, i) => {
      surveyQuotas.push({
        definitions: quotas.map(({ logicalModifier, optionQuota, options }) => {
          let modifierLabel = '';
          if (logicalModifier === 'all') {
            modifierLabel = 'All';
          } else if (logicalModifier === 'none') {
            modifierLabel = 'No';
          } else if (logicalModifier === 'at_most') {
            modifierLabel = `At most ${optionQuota}`;
          } else if (logicalModifier === 'at_least') {
            modifierLabel = `At least ${optionQuota}`;
          }

          return (
            <span key={i}>
              <strong className="font-bold">{modifierLabel}</strong> responses
              for{' '}
              {options
                .map((optionIdx) => {
                  return questionOptions[optionIdx].title;
                })
                .join(' or ')}
            </span>
          );
        }),
        questionTitle: `Q${sort}) ${title}`,
      });
    },
  );

  return surveyQuotas;
}

const ReviewSurveySummary = ({
  questions,
  survey,
  surveyVariables,
}: {
  questions: Question[];
  survey: Survey;
  surveyVariables: SurveyVariable[];
}): JSX.Element => {
  return (
    <Card>
      <h1 className="mb-2">Summary</h1>
      <div className="space-y-4 text-sm">
        <Overview questions={questions} survey={survey} />
        <hr className="text-light-grey" />
        <SurveyLinks survey={survey} />
        <hr className="text-light-grey" />
        <HiddenVariables survey={survey} surveyVariables={surveyVariables} />
        <hr className="text-light-grey" />
        <Quotas questions={questions} survey={survey} />
      </div>
    </Card>
  );
};

export default ReviewSurveySummary;

const HeaderWithEdit = ({
  onClickEdit,
  title,
}: {
  onClickEdit(): void;
  title: string;
}): JSX.Element => {
  return (
    <h2 className="flex items-center space-x-2">
      <span className="font-bold">{title}</span>
      <IconBackground onClick={onClickEdit}>
        <div className="w-3 h-3">
          <Icon id="pencil" />
        </div>
      </IconBackground>
    </h2>
  );
};

const Overview = ({
  questions,
  survey,
}: {
  questions: Question[];
  survey: Survey;
}): JSX.Element => {
  const navigate = useNavigate();

  return (
    <div className="space-y-2">
      <HeaderWithEdit
        onClickEdit={() => {
          navigate(`/campaign/edit/${survey.id}/overview`);
        }}
        title="Overview"
      />
      <div className="flex items-center">
        <div className="w-32 text-dark-grey text-xs">Title:</div>
        <div>{survey.title}</div>
      </div>
      {survey.wave.waveValue > 1 && (
        <div className="flex items-center">
          <div className="w-32 text-dark-grey text-xs">Wave:</div>
          <div>{survey.wave.title}</div>
        </div>
      )}
      <div className="flex items-center">
        <div className="w-32 text-dark-grey text-xs"># of respondents:</div>
        <div>{survey.participants}</div>
      </div>
      <div className="flex items-center">
        <div className="w-32 text-dark-grey text-xs">Estimated incidence:</div>
        <div>{survey.incidenceType.name}</div>
      </div>
      <div className="flex items-center">
        <div className="w-32 text-dark-grey text-xs"># of questions:</div>
        <div>{questions.length}</div>
      </div>
    </div>
  );
};

const SurveyLinks = ({ survey }: { survey: Survey }) => {
  const isAdmin = useHasRole('admin');

  const { previewLink, testLink } = getTestLinks({ survey });
  const liveLink = getLiveLink({ isAdmin, survey });

  return (
    <div className="space-y-2">
      <h2 className="font-bold">Survey Links</h2>
      <div className="flex items-center space-x-2 text-sm">
        <div className="truncate">
          <Hyperlink href={testLink}>Test Link</Hyperlink>
        </div>
        <CopyToClipboard text={testLink} />
      </div>
      <div className="flex items-center space-x-2 text-sm">
        <div className="truncate">
          <Hyperlink href={previewLink}>Preview Link</Hyperlink>
        </div>
        <CopyToClipboard text={previewLink} />
      </div>
      {liveLink && (
        <div className="flex items-center space-x-2 text-sm">
          <div className="truncate">
            <Hyperlink href={liveLink}>Live Link</Hyperlink>
          </div>
          <CopyToClipboard text={liveLink} />
        </div>
      )}
    </div>
  );
};

const HiddenVariables = ({
  survey,
  surveyVariables,
}: {
  survey: Survey;
  surveyVariables: SurveyVariable[];
}): JSX.Element => {
  const navigate = useNavigate();

  return (
    <div className="space-y-2">
      <HeaderWithEdit
        onClickEdit={() => {
          const variableId =
            surveyVariables.length > 0 ? `/${surveyVariables[0].id}` : '';

          navigate(`/campaign/edit/${survey.id}/variables${variableId}`);
        }}
        title="Hidden Variables"
      />
      {surveyVariables.length === 0 && (
        <p className="text-dark-grey">No hidden variables</p>
      )}
      {surveyVariables.map(({ quotas, segments, title }, i) => {
        return (
          <div key={i}>
            <p className="italic">{title}</p>
            <ul className="list-disc pl-8">
              {segments.map(({ quotaId, title }, i) => {
                const quota = quotas.find(({ id }) => id === quotaId);
                let quotaStr = '';

                if (quota) {
                  if (quota.type === 'all') {
                    quotaStr = '(All)';
                  } else if (quota.type === 'at_most') {
                    quotaStr = `(At most ${quota.numberNeeded})`;
                  } else if (quota.type === 'none') {
                    quotaStr = '(None)';
                  }
                }

                return (
                  <li key={i}>
                    {title}
                    {quotaStr && ` ${quotaStr}`}
                  </li>
                );
              })}
            </ul>
          </div>
        );
      })}
    </div>
  );
};

const Quotas = ({
  questions,
  survey,
}: {
  questions: Question[];
  survey: Survey;
}): JSX.Element => {
  const navigate = useNavigate();

  const surveyQuotas = [
    ...getSurveyQuotasFromAudience(survey),
    ...getSurveyQuotasFromQuestions(questions),
  ];

  return (
    <div className="space-y-2">
      <HeaderWithEdit
        onClickEdit={() => {
          navigate(`/campaign/edit/${survey.id}/audience`);
        }}
        title="Quotas"
      />
      {surveyQuotas.length === 0 && <p className="text-dark-grey">No quotas</p>}
      {surveyQuotas.map(({ definitions, questionTitle }, i) => {
        return (
          <div key={i}>
            <p className="italic">{questionTitle}</p>
            <ul className="list-disc pl-8">
              {definitions.map((definition, i) => {
                return <li key={i}>{definition}</li>;
              })}
            </ul>
          </div>
        );
      })}
    </div>
  );
};
