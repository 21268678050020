import { getEnvVar } from './env';
import {
  IncidenceType,
  LOI,
  PanelProvider,
  Survey,
} from '../types/domainModels';

export type DeliverableService =
  | 'extendedDeck'
  | 'none'
  | 'signatureDeck'
  | 'toplineDeck';

export type QuestionnaireService =
  | 'fieldManagement'
  | 'none'
  | 'questionnaireReview';

const COST_MAP: {
  deliverableRates: {
    [deliverable in DeliverableService]: number;
  };
  loiRates: {
    [loi in LOI]: {
      [incidence in IncidenceType['name']]: number;
    };
  };
  questionnaireRates: {
    [loi in LOI]: {
      [questionnaireService in QuestionnaireService]: number;
    };
  };
} = {
  deliverableRates: {
    extendedDeck: 7500,
    none: 0,
    signatureDeck: 5000,
    toplineDeck: 3000,
  },
  loiRates: {
    '1-20': {
      '100-75%': 2.8,
      '74-50%': 3.36,
      '49-30%': 4.48,
      '29-20%': 6.72,
      '19-10%': 7.84,
      '9-5%': 12.32,
      '4-3%': 20.16,
      '2-1%': 33.6,
    },
    '21-40': {
      '100-75%': 3.6,
      '74-50%': 4.31,
      '49-30%': 5.75,
      '29-20%': 8.63,
      '19-10%': 10.06,
      '9-5%': 15.81,
      '4-3%': 25.88,
      '2-1%': 40.0,
    },
    '41-60': {
      '100-75%': 4.23,
      '74-50%': 5.06,
      '49-30%': 6.75,
      '29-20%': 9.75,
      '19-10%': 11.38,
      '9-5%': 17.88,
      '4-3%': 29.25,
      '2-1%': 45.0,
    },
  },
  questionnaireRates: {
    '1-20': {
      fieldManagement: 1200,
      none: 0,
      questionnaireReview: 400,
    },
    '21-40': {
      fieldManagement: 1400,
      none: 0,
      questionnaireReview: 500,
    },
    '41-60': {
      fieldManagement: 1600,
      none: 0,
      questionnaireReview: 600,
    },
  },
};

const PANEL_PROVIDER_QUERY_PARAMS: Record<PanelProvider, string> = {
  LUCID:
    'provider=lucid&lucidSurveyId=[%RSFN%]&RID=[%RID%]&respondentIncentive=[%COST%]',
  MFOUR: '?provider=mfour&RID=[%RID%]',
  MINDFORCE: '?provider=mindforce&RID=[%RID%]',
  PURE_SPECTRUM:
    '?provider=purespectrum&RID=[%transaction_id%]&ps_hash=[%ps_hash%]',
  REP_DATA: '?provider=repdata&RID=[%RID%]',
  ROI: '?provider=ROI&RID=[%RID%]',
  SYMMETRIC: '?provider=symmetric&RID=[%RID%]',
};

const RESPONDENT_HOST = getEnvVar('RESPONDENT_HOST');

export function calculateEstimatedSurveyCost({
  deliverableService = 'none',
  estimatedIncidence,
  numParticipants,
  numQuestions,
  questionnaireService = 'none',
}: {
  deliverableService?: DeliverableService;
  estimatedIncidence: IncidenceType['name'] | undefined;
  numParticipants: number;
  numQuestions: LOI | number | undefined;
  questionnaireService?: QuestionnaireService;
}): number {
  if (
    estimatedIncidence === undefined ||
    Number.isNaN(numParticipants) ||
    numQuestions === undefined
  ) {
    return 0;
  }

  let loi: LOI = '1-20';
  if (typeof numQuestions === 'number') {
    if (numQuestions > 40) {
      loi = '41-60';
    } else if (numQuestions > 20) {
      loi = '21-40';
    }
  } else {
    loi = numQuestions;
  }

  const surveyCost =
    numParticipants * COST_MAP.loiRates[loi][estimatedIncidence] +
    COST_MAP.deliverableRates[deliverableService] +
    COST_MAP.questionnaireRates[loi][questionnaireService];

  return Number(surveyCost.toFixed(2));
}

export function getLiveLink({
  isAdmin,
  survey,
}: {
  isAdmin: boolean;
  survey: Survey;
}) {
  // Admins:
  //   - Can see live links for all non-Lucid surveys (BYOA + other sample providers).
  // Non-admins:
  //   - Can see live links for only BYOA surveys.
  if (isLucidSurvey(survey) || (!isAdmin && !survey.isBringYourOwnAudience)) {
    return;
  }

  const url = getSurveyResponseURL(survey);

  let liveLink = url;
  if (survey.panelProvider) {
    liveLink = `${url}${PANEL_PROVIDER_QUERY_PARAMS[survey.panelProvider]}`;
  }

  return liveLink;
}

function getSurveyResponseURL(survey: Survey) {
  return `${RESPONDENT_HOST}/surveys/${survey.hash}`;
}

export function getTestLinks({
  startingQuestionID = undefined,
  survey,
}: {
  startingQuestionID?: number;
  survey: Survey;
}) {
  const url = getSurveyResponseURL(survey);

  const previewParams = new URLSearchParams([
    ['mode', 'testing'],
    ['testView', 'collapsed'],
  ]);
  const testParams = new URLSearchParams([['mode', 'testing']]);

  if (startingQuestionID) {
    previewParams.append('startingQuestionID', startingQuestionID.toString());
    testParams.append('startingQuestionID', startingQuestionID.toString());
  }

  return {
    previewLink: `${url}?${previewParams}`,
    testLink: `${url}?${testParams}`,
  };
}

export function isLucidSurvey(survey: {
  isBringYourOwnAudience: boolean;
  panelProvider: PanelProvider | null;
}) {
  return (
    !survey.isBringYourOwnAudience &&
    (!survey.panelProvider || survey.panelProvider === 'LUCID')
  );
}
