import { FormikErrors } from 'formik';

import {
  AudienceLogicalModifier,
  PanelProvider,
  Question,
  QuestionOption,
  Survey,
  SurveyQualityCheck,
} from '../types/domainModels';
import { CreateAudienceBody } from '../services/backend/audience';
import { getQuestionOption } from './formOptions';
import { ReactSelectValue } from '../types/forms';
import { UpdateInboundCensusBody } from '../services/backend/surveys';
import {
  compact,
  isArray,
  cloneDeep,
  castArray,
  some,
  isEmpty,
  reduce,
} from 'lodash-es';

export type AudienceSource = 'byoa' | 'sample-provider';
export type AudienceTypeOptions = 'custom' | 'natural';

export enum DEMOGRAPHIC_QUOTA_TYPE {
  ENUM = 2,
  OPEN_END = 3,
  RANGE = 10,
}

export type DemographicConstraint =
  | QuestionOption
  | {
      end: number | '';
      start: number | '';
    };
export type DemographicConstraintValidated =
  | QuestionOption
  | {
      end: number;
      start: number;
    };

export interface DemographicFormData {
  audienceProfile: {
    audienceSource: AudienceSource;
    audienceType: AudienceTypeOptions;
    demographicQuotas: DemographicQuota[];
    inboundCensus: {
      useAge: boolean;
      useEthnicity: boolean;
      useGender: boolean;
      useRace: boolean;
      useRegion: boolean;
    };
    panelProvider: PanelProvider | null;
    title: string;
  };
  respondentQuality: {
    qualityChecks: {
      bad_respondent: boolean;
      copy_paste: boolean;
      duplicate: boolean;
      presurvey_questionnaire: boolean;
    };
  };
}

export interface DemographicFormDataValidated {
  audienceProfile: {
    audienceSource: AudienceSource;
    audienceType: AudienceTypeOptions;
    demographicQuotas: {
      demographic: ReactSelectValue<Question>;
      quotas: {
        constraints: QuotaConstraintsValidated;
        logicalModifier: ReactSelectValue<AudienceLogicalModifier>;
        percentage: number;
      }[];
    }[];
    inboundCensus: {
      useAge: boolean;
      useEthnicity: boolean;
      useGender: boolean;
      useRace: boolean;
      useRegion: boolean;
    };
    panelProvider: PanelProvider | null;
    title: string;
  };
  respondentQuality: {
    qualityChecks: {
      bad_respondent: boolean;
      copy_paste: boolean;
      duplicate: boolean;
      presurvey_questionnaire: boolean;
    };
  };
}

export interface DemographicQuota {
  demographic: ReactSelectValue<Question> | null;
  quotas: Quota[];
}

export const AUDIENCE_SOURCE_OPTIONS = [
  { label: 'Glass Survey Audience', value: 'sample-provider' },
  { label: 'My Own Audience', value: 'byoa' },
] satisfies ReactSelectValue<AudienceSource>[];

export const LOGICAL_MODIFIER_OPTIONS_MAP: {
  [id in DEMOGRAPHIC_QUOTA_TYPE]: ReactSelectValue<AudienceLogicalModifier>[];
} = {
  [DEMOGRAPHIC_QUOTA_TYPE.ENUM]: [
    { label: 'are', value: 'is' },
    { label: 'are not', value: 'isnt' },
    { label: 'are either', value: 'should' },
    // { label: 'are not any of', value: 'shouldnt' },
  ],
  [DEMOGRAPHIC_QUOTA_TYPE.RANGE]: [
    { label: 'are between', value: 'is' },
    { label: 'are not between', value: 'isnt' },
    { label: 'are between either', value: 'should' },
    // { label: 'are not between any of', value: 'shouldnt' },
  ],
  [DEMOGRAPHIC_QUOTA_TYPE.OPEN_END]: [
    { label: 'are between', value: 'is' },
    { label: 'are not between', value: 'isnt' },
    { label: 'are between either', value: 'should' },
    // { label: 'are not between any of', value: 'shouldnt' },
  ],
};

export interface Quota {
  constraints: QuotaConstraints;
  logicalModifier: ReactSelectValue<AudienceLogicalModifier> | null;
  percentage: number | '';
}

export type QuotaConstraints =
  | ReactSelectValue<DemographicConstraint>
  | (DemographicConstraint | ReactSelectValue<DemographicConstraint>)[];
export type QuotaConstraintsValidated =
  | ReactSelectValue<DemographicConstraintValidated>
  | (
      | DemographicConstraintValidated
      | ReactSelectValue<DemographicConstraintValidated>
    )[];

export function apiDataToFormData({
  demographicQuestions,
  survey,
}: {
  demographicQuestions: Question[];
  survey: Survey;
}): DemographicFormData {
  const audienceType = survey.audienceId === 0 ? 'natural' : 'custom';
  let demographicQuotas = [getEmptyDemographicQuota()];
  let title = '';

  if (audienceType === 'custom') {
    title = survey.audience.title;
    demographicQuotas = survey.audience.audienceSlices.map((slice) => {
      const demographicQuestion =
        demographicQuestions.find(({ id }) => {
          return id === slice.audienceSliceCategories[0].questionId;
        }) ?? null;
      const modifierOptions = demographicQuestion
        ? getLogicalModifierOptions(demographicQuestion)
        : [];

      return {
        demographic: demographicQuestion
          ? getQuestionOption({ question: demographicQuestion })
          : null,
        quotas: slice.audienceSliceCategories.map((category) => {
          const constraints = compact(
            category.audienceSliceCategoryAttributes.map(
              ({ audienceAttribute }) => {
                if (audienceAttribute.enumValueId) {
                  const option = demographicQuestion?.options.find(({ id }) => {
                    return id === audienceAttribute.enumValueId;
                  });
                  if (!option) {
                    return null;
                  }

                  return { label: option.title, value: option };
                }

                if (audienceAttribute.numberRange) {
                  return {
                    end: audienceAttribute.numberRange.end,
                    start: audienceAttribute.numberRange.start,
                  };
                }

                return null;
              },
            ),
          );

          return {
            constraints,
            logicalModifier:
              modifierOptions.find(({ value }) => {
                return value === category.logicalModifier;
              }) ?? null,
            percentage: Number(category.percentage) * 100,
          };
        }),
      };
    });
  }

  return {
    audienceProfile: {
      audienceSource: survey.isBringYourOwnAudience
        ? 'byoa'
        : 'sample-provider',
      audienceType,
      demographicQuotas,
      inboundCensus: {
        useAge: survey.useAgeCensus,
        useEthnicity: survey.useEthnicityCensus,
        useGender: survey.useGenderCensus,
        useRace: survey.useRaceCensus,
        useRegion: survey.useRegionCensus,
      },
      panelProvider: survey.isBringYourOwnAudience
        ? null
        : survey.panelProvider ?? 'LUCID',
      title,
    },
    respondentQuality: {
      qualityChecks: {
        bad_respondent: getInitialQualityCheck({
          qualityChecks: survey.qualityChecks,
          type: 'bad_respondent',
        }),
        copy_paste: getInitialQualityCheck({
          qualityChecks: survey.qualityChecks,
          type: 'copy_paste',
        }),
        duplicate: getInitialQualityCheck({
          qualityChecks: survey.qualityChecks,
          type: 'duplicate',
        }),
        presurvey_questionnaire: getInitialQualityCheck({
          qualityChecks: survey.qualityChecks,
          type: 'presurvey_questionnaire',
        }),
      },
    },
  };
}

export function formDataToApiData({
  formData,
  surveyId,
}: {
  formData: DemographicFormDataValidated;
  surveyId?: number;
}): CreateAudienceBody {
  const { demographicQuotas, title } = formData.audienceProfile;

  const audienceSlices = demographicQuotas.map(({ demographic, quotas }) => {
    return {
      audienceSliceCategories: quotas.map((quota) => {
        const constraints = isArray(quota.constraints)
          ? quota.constraints
          : [quota.constraints];

        return {
          audienceSliceCategoryAttributes: constraints.map((constraint) => {
            const constraintValue =
              'value' in constraint ? constraint.value : constraint;

            if ('id' in constraintValue) {
              return constraintValue.id;
            }

            return constraintValue;
          }),
          logicalModifier: quota.logicalModifier.value,
          percentage: Math.round(quota.percentage * 100000) / 10000000,
          question: demographic.value,
          questionId: demographic.value.id,
        };
      }),
      percentage: 1,
    };
  });

  return {
    audienceSlices,
    // TODO: Not sure what dependentSlices means...
    dependentSlices: false,
    // TODO: Not sure what isPublic means...
    isPublic: false,
    surveyId,
    title,
  };
}

export function formInboundCensusToApiData({
  formData,
}: {
  formData: DemographicFormDataValidated;
}): UpdateInboundCensusBody {
  const {
    useAge: useAgeCensus,
    useEthnicity: useEthnicityCensus,
    useGender: useGenderCensus,
    useRace: useRaceCensus,
    useRegion: useRegionCensus,
  } = formData.audienceProfile.inboundCensus;

  return {
    useAgeCensus,
    useEthnicityCensus,
    useGenderCensus,
    useRaceCensus,
    useRegionCensus,
  };
}

export function getInitialQualityCheck({
  qualityChecks,
  type,
}: {
  qualityChecks: SurveyQualityCheck[];
  type: SurveyQualityCheck['type'];
}) {
  return (
    qualityChecks.find((qualityCheck) => qualityCheck.type === type)?.enabled ??
    true
  );
}

export function getEmptyDemographicQuota(
  demographic?: Question | null,
): DemographicQuota {
  return {
    demographic: null,
    quotas: [getEmptyQuota(demographic)],
  };
}

export function getEmptyQuota(demographic?: Question | null): Quota {
  const constraints =
    demographic?.questionTypeId === DEMOGRAPHIC_QUOTA_TYPE.RANGE
      ? [getEmptyQuotaRangeConstraint()]
      : [];
  const logicalModifierOptions = demographic
    ? getLogicalModifierOptions(demographic)
    : [];

  return {
    constraints,
    logicalModifier:
      logicalModifierOptions.length > 0 ? logicalModifierOptions[0] : null,
    percentage: '',
  };
}

export function getEmptyQuotaRangeConstraint(): { end: ''; start: '' } {
  return { end: '', start: '' };
}

export function getLogicalModifierOptions(
  demographic: Question,
): ReactSelectValue<AudienceLogicalModifier>[] {
  // TODO: Address compilation error
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  return LOGICAL_MODIFIER_OPTIONS_MAP[demographic.questionTypeId] || [];
}

export function getValuesForAudienceTypeChange({
  formData,
  newAudienceType,
}: {
  formData: DemographicFormData;
  newAudienceType: DemographicFormData['audienceProfile']['audienceType'];
}): DemographicFormData {
  const newFormData = cloneDeep(formData);
  newFormData.audienceProfile.audienceType = newAudienceType;

  if (newAudienceType === 'natural') {
    newFormData.audienceProfile.demographicQuotas = [
      getEmptyDemographicQuota(),
    ];
    newFormData.audienceProfile.title = '';
  }

  return newFormData;
}

export function isMultiSelectLogicalModifier(
  logicalModifier: AudienceLogicalModifier,
): boolean {
  return logicalModifier === 'should' || logicalModifier === 'shouldnt';
}

export function validateDemographicFormData(
  formData: DemographicFormData,
): FormikErrors<DemographicFormData> {
  if (formData.audienceProfile.audienceType !== 'custom') {
    return {};
  }

  const errors: FormikErrors<DemographicFormData> = {};
  if (!formData.audienceProfile.title) {
    errors.audienceProfile = {
      ...errors.audienceProfile,
      title: 'Please provide a title',
    };
  }

  const demographicQuotasErrors: FormikErrors<DemographicQuota>[] = [];
  formData.audienceProfile.demographicQuotas.forEach((demographicQuota) => {
    const demographicQuotaErrors: FormikErrors<DemographicQuota> = {};

    if (!demographicQuota.demographic) {
      demographicQuotaErrors.demographic = 'Please choose a demographic.';
    }

    const quotasErrors: FormikErrors<Quota>[] = [];
    demographicQuota.quotas.forEach((quota) => {
      const quotaErrors: FormikErrors<Quota> = {};

      if (castArray(quota.constraints).length === 0) {
        // Formik doesn't like this specification for the error but it's perfectly acceptable.
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        quotaErrors.constraints = 'Required' as any;
      }

      const constraintsErrors: FormikErrors<Quota['constraints']> = [];
      castArray(quota.constraints).forEach((constraint) => {
        const constraintErrors: FormikErrors<{ end: string; start: string }> =
          {};

        if ('end' in constraint) {
          if (constraint.end === '') {
            constraintErrors.end = 'Required';
          } else if (constraint.end <= 0) {
            constraintErrors.end = 'Must be positive';
          } else if (constraint.end <= 12) {
            constraintErrors.end = 'Must be 13 or older.';
          }

          if (constraint.start === '') {
            constraintErrors.start = 'Required';
          } else if (constraint.start <= 0) {
            constraintErrors.start = 'Must be positive';
          } else if (constraint.start <= 12) {
            constraintErrors.start = 'Must be 13 or older.';
          }
        }

        constraintsErrors.push(constraintErrors);
      });

      if (some(constraintsErrors, (errors) => !isEmpty(errors))) {
        quotaErrors.constraints = constraintsErrors;
      }

      if (!quota.logicalModifier) {
        quotaErrors.logicalModifier = 'Required';
      }

      if (!quota.percentage) {
        quotaErrors.percentage = 'Required';
      }

      quotasErrors.push(quotaErrors);
    });

    const quotasSum = reduce(
      demographicQuota.quotas,
      (currentSum, quota) => {
        return currentSum + (quota.percentage || 0);
      },
      0,
    );

    if (some(quotasErrors, (errors) => !isEmpty(errors))) {
      demographicQuotaErrors.quotas = quotasErrors;
    } else if (quotasSum !== 100) {
      demographicQuotaErrors.quotas = `Percentages must add up to 100%. ${
        100 - quotasSum
      }% left.`;
    }

    demographicQuotasErrors.push(demographicQuotaErrors);
  });

  if (some(demographicQuotasErrors, (errors) => !isEmpty(errors))) {
    errors.audienceProfile = {
      ...errors.audienceProfile,
      demographicQuotas: demographicQuotasErrors,
    };
  }

  return errors;
}
