import { cloneDeep } from 'lodash-es';
import { useField, FieldHelperProps } from 'formik';

import {
  getOtherQuestionReferencesForConcept,
  getVariableReferencesForConcept,
  IQuestionReferenceForOption,
  IVariableReference,
} from '../../util/questions';
import { OPTION_FEATURES, OptionFeatureConfig } from '../../util/options';
import { Question, SurveyVariable } from '../../types/domainModels';
import { QuestionFormData, QuestionFormOption } from '../../types/forms';
import { useModal } from '../../hooks/modals';

import Button from 'components/common/forms/Button';
import FormInput from '../common/forms/FormInput';
import MediaUploadWithCrop from '../common/MediaUploadWithCrop';
import OptionFeatureLabel from './OptionFeatureLabel';
import OptionSettings from './OptionSettings';
import PreventDeleteConceptModal from './PreventDeleteConceptModal';
import XButton from '../common/forms/XButton';

const QuestionConcept = ({
  index,
  onClickRemove,
  question,
  questions,
  variables,
}: {
  index: number;
  onClickRemove?: () => void;
  question: Question | undefined;
  questions: Question[];
  variables: SurveyVariable[];
}) => {
  const fieldPrefix = `concepts.${index}`;
  const [{ value: concept }] = useField<QuestionFormOption>(`${fieldPrefix}`);

  const [{ value: optionFeatures }, , optionFeatureHelpers] = useField<
    QuestionFormOption['features']
  >(`${fieldPrefix}.features`);
  const [{ value: questionType }] =
    useField<QuestionFormData['questionType']>('questionType');

  const [, , weightHelper] = useField<QuestionFormOption['weight']>(
    `${fieldPrefix}.weight`,
  );
  const [{ value: isActive }, , activeHelper] = useField(
    `concepts.${index}.features.isActive`,
  );

  return (
    <div className="p-2">
      <div className="flex items-center w-full space-x-4">
        <ConceptImage fieldPrefix={fieldPrefix} />

        <div className="flex-grow">
          <FormInput
            name={`${fieldPrefix}.value`}
            placeholder="Concept Name..."
            size="md"
            type="text"
          />

          <div className="space-x-2">
            {optionFeatures.displayLogic &&
              optionFeatures.displayLogic.enabled && (
                <OptionFeatureLabel
                  label="Display Logic"
                  onClickRemove={() => {
                    const newOptionFeatures = cloneDeep(optionFeatures);
                    newOptionFeatures.displayLogic.enabled = false;

                    optionFeatureHelpers.setValue(newOptionFeatures);
                  }}
                />
              )}
            {!isActive && <OptionFeatureLabel label="Inactive" />}
          </div>
        </div>

        <div className="flex items-center space-x-2">
          <OptionActions
            activeHelper={activeHelper}
            availableOptionFeatures={OPTION_FEATURES.filter(
              // This will result in an arrary of length 1
              (feature) => feature.apiName === 'isActive',
            )}
            conceptId={concept.id}
            index={index}
            namePrefix="concepts"
            onClickRemove={onClickRemove}
            question={question}
            questionType={questionType}
            questions={questions}
            variables={variables}
            weightHelper={weightHelper}
          />
        </div>
      </div>
    </div>
  );
};

export default QuestionConcept;

const ConceptImage = ({
  fieldPrefix,
}: {
  fieldPrefix: string;
}): JSX.Element => {
  const [{ value: media }, , mediaHelpers] = useField<
    QuestionFormOption['image']
  >(`${fieldPrefix}.image`);

  return (
    <MediaUploadWithCrop
      media={media}
      onCrop={(preview) => {
        mediaHelpers.setValue({ data: null, preview });
      }}
      onPreviewLoaded={(preview) => {
        mediaHelpers.setValue({ data: null, preview });
      }}
      onUploadError={() => {
        mediaHelpers.setError('Failed to upload image. Please try again.');
        mediaHelpers.setTouched(true);
      }}
      onUploaded={(dataUrl) => {
        mediaHelpers.setValue({
          data: dataUrl,
          preview: media.preview,
        });
      }}
      trigger={
        <Button hierarchy="secondary-gray" size="xs">
          Upload
        </Button>
      }
    />
  );
};

const OptionActions = ({
  activeHelper,
  availableOptionFeatures,
  index,
  namePrefix,
  onClickRemove,
  conceptId,
  question,
  questions,
  questionType,
  weightHelper,
  variables,
}: {
  activeHelper: FieldHelperProps<boolean>;
  availableOptionFeatures: OptionFeatureConfig[];
  index: number;
  namePrefix: 'concepts' | 'labels' | 'options';
  onClickRemove?: () => void;
  conceptId: number | null;
  question: Question | undefined;
  questions: Question[];
  questionType: QuestionFormData['questionType'];
  weightHelper: FieldHelperProps<number | null>;
  variables: SurveyVariable[];
}) => {
  const {
    isOpen: isPreventDeleteModalOpen,
    onCloseModal,
    setIsOpen: setIsPreventDeleteModalOpen,
  } = useModal();

  return (
    <>
      <OptionSettings
        activeHelper={activeHelper}
        availableOptionFeatures={availableOptionFeatures}
        index={index}
        isWithinMonadicLoop={!!question?.monadicId}
        namePrefix={namePrefix}
        optionId={conceptId}
        question={question}
        questionType={questionType}
        questions={questions}
        setIsPreventDeleteModalOpen={setIsPreventDeleteModalOpen}
        variables={variables}
        weightHelper={weightHelper}
      />
      {onClickRemove && (
        <XButton
          onClick={() => {
            if (conceptId) {
              let variableReferences: IVariableReference[] = [];
              let questionReferences: IQuestionReferenceForOption[] = [];
              if (questions) {
                questionReferences = getOtherQuestionReferencesForConcept({
                  conceptId,
                  questions,
                });
              }
              if (variables) {
                variableReferences = getVariableReferencesForConcept({
                  conceptId,
                  variables,
                });
              }
              const hasOtherReferences =
                questionReferences.length > 0 || variableReferences.length > 0;

              if (hasOtherReferences) {
                setIsPreventDeleteModalOpen(true);

                return;
              }
            }

            onClickRemove();
          }}
          title="Remove"
        />
      )}

      {isPreventDeleteModalOpen && conceptId && (
        <PreventDeleteConceptModal
          conceptId={conceptId}
          onCloseModal={onCloseModal}
          questions={questions}
          variables={variables}
        />
      )}
    </>
  );
};
