import { Question, SurveyVariable } from '../../../types/domainModels';
import { QuestionFormOption } from '../../../types/forms';

import CarryForward from '../questionFeatures/CarryForward';
import ConceptField from '../ConceptField';
import DisplayLogic from '../questionFeatures/DisplayLogic';
import DisplayOptionDescription from '../questionFeatures/DisplayOptionDescription';
import DisplayXOfY from '../questionFeatures/DisplayXOfY';
import MultipleResponse from '../questionFeatures/MultipleResponse';
import PipeConcept from '../questionFeatures/PipeConcept';
import QuestionOptions from '../QuestionOptions';
import Quotas from '../questionFeatures/Quotas';
import RandomizeOptions from '../questionFeatures/RandomizeOptions';
import ViewAllImages from '../questionFeatures/ViewAllImages';
import ViewConcept from '../questionFeatures/ViewConcept';
import SurveyEditRow from '../SurveyEditRow';
import SurveyEditRowLeftSide from '../SurveyEditRowLeftSide';

const Ranking = ({
  concepts = [],
  pipeConcept = false,
  question,
  questions,
  variables,
}: {
  concepts?: QuestionFormOption[];
  pipeConcept?: boolean;
  question: Question | undefined;
  questions: Question[];
  variables: SurveyVariable[];
}) => {
  return (
    <>
      <SurveyEditRow
        layout={concepts.length > 0 ? 'column' : 'row'}
        subtitle="(optional)"
        title="Concept Image or Video"
        tooltip="Upload either image or video files. Suggested formats are jpeg/png for images or mp4 for video."
      >
        <ConceptField
          concepts={concepts}
          disabled={pipeConcept}
          question={question}
          questions={questions}
          upperLimit={1}
        />
      </SurveyEditRow>
      <div className="p-6 border-b border-gray-300">
        <SurveyEditRowLeftSide title="Options" />
        <QuestionOptions
          question={question}
          questions={questions}
          variables={variables}
        />
      </div>

      <div className="p-6 border-b border-gray-300">
        <SurveyEditRowLeftSide title="Option Features" />
        <div className="mx-4 mt-4 space-y-4">
          <MultipleResponse
            isMultipleResponseDisabled={true}
            tooltip="Ranking questions are always multiple response."
          />
          <RandomizeOptions />
          <DisplayXOfY />
          <ViewAllImages />
          <DisplayOptionDescription />
          <CarryForward question={question} questions={questions} />
        </div>
      </div>

      <div className="p-6">
        <SurveyEditRowLeftSide title="Question Features" />
        <div className="mx-4 mt-4 space-y-4">
          <PipeConcept
            concepts={concepts}
            question={question}
            questions={questions}
          />
          <ViewConcept />
          <Quotas />
          <DisplayLogic question={question} questions={questions} />
        </div>
      </div>
    </>
  );
};

export default Ranking;
