import axios from 'axios';

import {
  BannerCompact,
  Export,
  ExportFilterModifier,
  Question,
  QuestionBase,
  QuestionConcept,
  QuestionOption,
  SurveyVariable,
} from '../../types/domainModels';
import { getAPIUrl } from './baseAPI';
import { queryStringify } from 'util/api';

export type SaveBannerOption =
  | // Multiple choice
  QuestionOption
  // Idea presenter
  | { conceptId: QuestionConcept }
  // Ranking & scale
  | {
      enumValue: number | undefined;
      highOption: number | '';
      lowOption: number | '';
    }
  // Matrix
  | { enumStringValues: string; enumValue: QuestionOption | undefined }
  | { matrixOptionId: number; enumValue: QuestionOption | undefined }
  // Number questions (like age demographic)
  | {
      highOption: number | '';
      lowOption: number | '';
    }
  // Hidden variables
  | {
      id: number;
    };

// This body is allowed to have undefined values in it. The API is flexible to allow you
// to save an export in any state.
export interface SaveExportBannersBody {
  averages: number[];
  bannerId: number | null;
  bottomBox: { questionId: number; value: number }[];
  categories: {
    base: boolean;
    categories: {
      attributes: {
        logicalModifier: ExportFilterModifier | undefined;
        options?: SaveBannerOption[];
        percentage: 100;
        segments?: SaveBannerOption[];
      }[];
      concept?: QuestionConcept | null;
      question?: Question | SurveyVariable;
      variableId?: number;
    }[];
    stat: boolean;
    title: string;
  }[];
  confidenceLevel: number | null;
  excludeQuestionIds: number[];
  exposedToQuestionIds: number[];
  rebaseIds: number[];
  stackComplexQuestions: boolean;
  surveyId: number;
  title: string;
  topBox: { questionId: number; value: number }[];
}

type Logic = 'is' | 'isnt' | 'should';
export type IdeaPresenterCondition = {
  conceptId?: number;
  id: number;
  logic: Logic;
  response: number[];
  type: 'ideaPresenter';
};
export type MatrixCondition = {
  conceptId?: number;
  id: number;
  logic: Logic;
  response: Record<string, string[]>;
  type: 'matrixV2';
};
export type MultipleChoiceCondition = {
  conceptId?: number;
  id: number;
  logic: Logic;
  response: number[];
  type: 'multipleChoice';
};
export type NumberCondition = {
  conceptId?: number;
  id: number;
  logic: Logic;
  response: [number, number];
  type: 'number';
};
export type RankingScaleCondition = {
  conceptId?: number;
  id: number;
  logic: Logic;
  response: Record<string, [number, number][]>;
  type: 'ranking' | 'scale';
};
type QuestionCondition =
  | IdeaPresenterCondition
  | MatrixCondition
  | MultipleChoiceCondition
  | NumberCondition
  | RankingScaleCondition;
export type SegmentCondition = { ids: number[]; logic: Logic };
export type WaveCondition = { ids: number[]; logic: Logic };

type ConditionsGroup = {
  conditions: {
    groupLogic: 'and' | 'or';
    groups: (
      | {
          data: QuestionCondition;
          type: 'question';
        }
      | {
          data: SegmentCondition;
          type: 'segment';
        }
      | {
          data: WaveCondition;
          type: 'wave';
        }
    )[][];
  };
  title: string;
}[];

export interface CreateExcelExportBody {
  banners: ConditionsGroup;
  calculatedMetrics: Record<
    string,
    {
      average?: boolean;
      base?: QuestionBase;
      bottomBox?: number;
      topBox?: number;
    }
  >;
  filters: ConditionsGroup;
  meta: {
    exportedAt: string;
    savedExportName?: string;
  };
  output: {
    excludeQuestionIds: number[];
    multiDimensionalQuestions: 'stack' | 'stretch';
  };
  statTesting?: { confidenceInterval: number };
}

export const CREATE_EXCEL_EXPORT = ({ surveyId }: { surveyId: number }) => ({
  path: `/surveys/${surveyId}:exportExcel`,
  version: 'v2' as const,
});

export async function createExcelExport({
  data,
  surveyId,
}: {
  data: CreateExcelExportBody;
  surveyId: number;
}) {
  return (
    await axios.post<undefined>(
      getAPIUrl(CREATE_EXCEL_EXPORT({ surveyId })),
      data,
    )
  ).data;
}

export const CREATE_RAW_DATA_EXPORT = ({ surveyId }: { surveyId: number }) => ({
  path: `/surveys/${surveyId}:exportRaw`,
  version: 'v2' as const,
});

export async function createRawDataExport({
  respondentType,
  surveyId,
  waveIds,
}: {
  respondentType: 'active' | 'all';
  surveyId: number;
  waveIds?: number[];
}) {
  return (
    await axios.post<undefined>(
      getAPIUrl(CREATE_RAW_DATA_EXPORT({ surveyId })),
      { respondentType, waveIds },
    )
  ).data;
}

export const CREATE_SPSS_EXPORT = ({ surveyId }: { surveyId: number }) => ({
  path: `/surveys/${surveyId}:exportSPSS`,
  version: 'v2' as const,
});

export async function createSPSSExport({
  surveyId,
  waveIds,
}: {
  surveyId: number;
  waveIds?: number[];
}) {
  return (
    await axios.post<undefined>(getAPIUrl(CREATE_SPSS_EXPORT({ surveyId })), {
      waveIds,
    })
  ).data;
}

export const DELETE_SAVED_EXPORT = ({
  savedExportId,
}: {
  savedExportId: number;
}) => ({
  path: `/surveys/results/banner/${savedExportId}`,
  version: 'v1' as const,
});

export async function deleteSavedExport({
  savedExportId,
}: {
  savedExportId: number;
}) {
  return (await axios.delete(getAPIUrl(DELETE_SAVED_EXPORT({ savedExportId }))))
    .data;
}

export const DOWNLOAD_EXPORT = ({ id }: { id: number }) => ({
  path: `/exports/${id}:download`,
  version: 'v2' as const,
});

export function downloadExport({ id }: { id: number }) {
  return axios.get(getAPIUrl(DOWNLOAD_EXPORT({ id })), {
    responseType: 'blob',
  });
}

export const EDIT_EXPORT = ({ exportId }: { exportId: number }) => ({
  path: `/exports/${exportId}`,
  version: 'v2' as const,
});

export async function editExport({
  data,
  exportId,
}: {
  data: Partial<{ displayStatus: Export['displayStatus'] }>;
  exportId: number;
}) {
  return (await axios.patch<Export>(getAPIUrl(EDIT_EXPORT({ exportId })), data))
    .data;
}

export const FETCH_EXPORTS = ({ surveyHash }: { surveyHash: string }) => ({
  path: `/surveys/${surveyHash}/exports`,
  version: 'v2' as const,
});

export async function fetchExports({
  showArchived,
  surveyHash,
}: {
  showArchived: boolean;
  surveyHash: string;
}) {
  return (
    await axios.get<Export[]>(getAPIUrl(FETCH_EXPORTS({ surveyHash })), {
      params: { includeArchived: showArchived ? 'true' : undefined },
    })
  ).data;
}

export const GENERATE_RAW_EXPORT = ({ surveyId }: { surveyId: number }) => ({
  path: `/surveys/xls/${surveyId}`,
  version: 'v1' as const,
});

export async function generateRawExport({
  surveyId,
  useReconciles,
  waveIds,
}: {
  surveyId: number;
  useReconciles?: boolean;
  waveIds?: number[];
}) {
  return (
    await axios.get(getAPIUrl(GENERATE_RAW_EXPORT({ surveyId })), {
      params: { ad: useReconciles ? 'true' : undefined, waveIds },
      // The default query param format for the waveIds array is waveIds[]=1&waveIds[]=2,
      // but the backend expects waveIds=1&waveIds=2.
      paramsSerializer: queryStringify,
    })
  ).data;
}

export const GENERATE_SPSS_EXPORT = ({ surveyId }: { surveyId: number }) => ({
  path: `/surveys/${surveyId}/spss`,
  version: 'v1' as const,
});

export async function generateSpssExport({
  surveyId,
  waveIds,
}: {
  surveyId: number;
  waveIds?: number[];
}) {
  return (
    await axios.get(getAPIUrl(GENERATE_SPSS_EXPORT({ surveyId })), {
      params: { waveIds },
      // The default query param format for the waveIds array is waveIds[]=1&waveIds[]=2,
      // but the backend expects waveIds=1&waveIds=2.
      paramsSerializer: queryStringify,
    })
  ).data;
}

export const SAVE_EXPORT_BANNERS = ({ surveyId }: { surveyId: number }) => ({
  path: `/surveys/results/banner/${surveyId}`,
  version: 'v1' as const,
});

export async function saveExportBanners({
  data,
  surveyId,
}: {
  data: SaveExportBannersBody;
  surveyId: number;
}) {
  return (
    await axios.post<BannerCompact[]>(
      getAPIUrl(SAVE_EXPORT_BANNERS({ surveyId })),
      data,
    )
  ).data;
}
