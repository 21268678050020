import { clsx } from 'clsx';
import { ReactNode } from 'react';
import { useField } from 'formik';

import { getMonadicBlockPromptQuestion } from 'util/questionBlocks';
import { Question, SurveyVariable } from '../../../types/domainModels';
import { QuestionFormData, QuestionFormOption } from '../../../types/forms';
import { useModal } from 'hooks/modals';

import Alert from 'components/common/Alert';
import Checkbox from 'components/common/forms/Checkbox';
import ConceptField from '../ConceptField';
import DisplayLogic from '../questionFeatures/DisplayLogic';
import FormInput from 'components/common/forms/FormInputNew';
import Hyperlink from 'components/common/Hyperlink';
import Icon from 'components/common/Icon';
import Modal from 'components/common/Modal';
import SurveyEditRow from '../SurveyEditRow';
import SurveyEditRowLeftSide from '../SurveyEditRowLeftSide';
import Tooltip from 'components/common/Tooltip';
import ViewConcept from '../questionFeatures/ViewConcept';

const IdeaPresenterQuestion = ({
  concepts = [],
  question,
  questions,
  variables,
}: {
  concepts?: QuestionFormOption[];
  question: Question | undefined;
  questions: Question[];
  variables: SurveyVariable[];
}) => {
  // We only currently support one sequential monadic block per survey, so we need
  // to disable the option if there already is one. However, we need to keep it enabled if the
  // user is looking at the question with it configured.
  const monadicPromptQuestion = getMonadicBlockPromptQuestion({ questions });
  const hasSequentialMonadicBlock = !!monadicPromptQuestion;
  const isViewingSequentialMonadicBlock =
    question && question.id === monadicPromptQuestion?.id;

  return (
    <>
      <div className="p-6 border-b border-gray-300">
        <MonadicSelections
          isSequentialMonadicDisabled={
            hasSequentialMonadicBlock && !isViewingSequentialMonadicBlock
          }
        />
      </div>
      <SurveyEditRow
        layout={concepts.length > 0 ? 'column' : 'row'}
        title="Concept Image or Video"
        tooltip="Upload either image or video files. Suggested formats are jpeg/png for images or mp4 for video."
      >
        <ConceptField
          concepts={concepts}
          question={question}
          questions={questions}
          variables={variables}
        />
      </SurveyEditRow>
      <div className="p-6">
        <SurveyEditRowLeftSide title="Question Features" />
        <div className="mx-4 mt-4 space-y-4">
          <ViewConcept />
          <DisplayLogic question={question} questions={questions} />
        </div>
      </div>
    </>
  );
};

export default IdeaPresenterQuestion;

/**
 * Presents different options to the user for monadic types: monadic or sequential monadic.
 * When sequential monadic is selected, additional configuration options are shown.
 */
const MonadicSelections = ({
  isSequentialMonadicDisabled,
}: {
  isSequentialMonadicDisabled: boolean;
}) => {
  const fieldName = 'features.monadicBlock.enabled' as const;
  const [{ value: monadicBlock }, , monadicBlockHelpers] =
    useField<QuestionFormData['features']['monadicBlock']['enabled']>(
      fieldName,
    );

  const {
    isOpen: isLearnMoreModalOpen,
    onCloseModal: onCloseLearnMoreModal,
    setIsOpen: setLearnMoreModalOpen,
  } = useModal();

  return (
    <div className="grid grid-cols-2 items-start gap-4">
      <label className="space-y-2">
        <Checkbox
          checked={!monadicBlock}
          label="Assign 1 Concept per Respondent"
          name={fieldName}
          onChange={() => {
            monadicBlockHelpers.setValue(false);
          }}
          radio
          tag="div"
        />
        <p className="text-gray-d-700">
          Respondents will be presented with one eligible concept.{' '}
          <Hyperlink
            onClick={(event) => {
              event.preventDefault();
              setLearnMoreModalOpen(true);
            }}
          >
            Learn more
          </Hyperlink>
        </p>
      </label>

      <div className="space-y-4">
        <label className="space-y-2">
          <Checkbox
            checked={monadicBlock}
            disabled={isSequentialMonadicDisabled}
            label="Assign Multiple Concepts per Respondent"
            name={fieldName}
            onChange={() => {
              monadicBlockHelpers.setValue(true);
            }}
            radio
            tag="div"
          />
          <p className="text-gray-d-700">
            Respondents will be prompted with the same set of questions,
            sequentially, about two or more concepts.{' '}
            <Hyperlink
              onClick={(event) => {
                event.preventDefault();
                setLearnMoreModalOpen(true);
              }}
            >
              Learn more
            </Hyperlink>
          </p>
        </label>
        {isSequentialMonadicDisabled && (
          <Alert
            color="gray"
            supportingText="Only one sequential monadic question is currently supported per
          survey."
          >
            Sequential Monadic Disabled
          </Alert>
        )}
        {monadicBlock && <MonadicSequentialOptions />}
      </div>

      {isLearnMoreModalOpen && (
        <MonadicExplainerDialog onCloseModal={onCloseLearnMoreModal} />
      )}
    </div>
  );
};

const MonadicSequentialOptions = () => {
  const [{ value: monadicStart }] = useField<
    QuestionFormData['features']['monadicBlock']['start']
  >('features.monadicBlock.start');

  return (
    <>
      <div className="flex items-center space-x-4">
        <label className="grow" htmlFor="concepts-per-respondent">
          Concepts per Respondent{' '}
          <div className="inline-flex">
            <Tooltip>
              The number of concepts to be shown to each respondent. Concepts
              will be displayed in random order.
            </Tooltip>
          </div>
        </label>
        <div className="w-24">
          <FormInput
            id="concepts-per-respondent"
            name="features.monadicBlock.sequences"
            size="md"
            type="number"
          />
        </div>
      </div>
      <div className="flex items-start space-x-4">
        <label className="grow">
          Question set to present for each concept{' '}
          <div className="inline-flex">
            <Tooltip>
              The first and last question questions to be shown for each
              concept. All questions between these will be shown for each
              concept.
            </Tooltip>
          </div>
        </label>
        <div className="flex space-x-2 items-center">
          <span>Q{monadicStart}</span>
          <span>to</span>
          <span>Q</span>
          <div className="w-24">
            <FormInput
              id="monadic-q-end"
              name="features.monadicBlock.end"
              size="md"
              type="number"
            />
          </div>
        </div>
      </div>
    </>
  );
};

const MonadicExplainerDialog = ({ onCloseModal }: { onCloseModal(): void }) => {
  return (
    <Modal onCloseModal={onCloseModal} size="auto">
      <div className="flex divide-x divide-gray-d-200">
        <div className="w-1/2 pr-4">
          <ConceptText
            subtitle="Monadic"
            title="Assign 1 Concept per Respondent"
          >
            Respondents see only one stimulus (randomly selected).
          </ConceptText>
        </div>
        <div className="w-1/2 pl-4">
          <ConceptText
            subtitle="Sequential Monadic"
            title="Assign Multiple Concepts per Respondent"
          >
            Respondents see the assigned number of stimuli in random order.
          </ConceptText>
        </div>
      </div>

      <div className="flex divide-x divide-gray-d-200">
        <div className="pt-4 pr-4 w-1/2 flex flex-col">
          <div className="space-y-4 flex flex-col items-center">
            <HorizontalConceptIllustration>
              <ConceptIllustration color="green" />
            </HorizontalConceptIllustration>
            <HorizontalConceptIllustration>
              <ConceptIllustration color="green-light" />
            </HorizontalConceptIllustration>
            <HorizontalConceptIllustration>
              <ConceptIllustration color="purple" />
            </HorizontalConceptIllustration>
            <HorizontalConceptIllustration>
              <ConceptIllustration color="blue" />
            </HorizontalConceptIllustration>
          </div>
        </div>
        <div className="pt-4 pl-4 w-1/2 flex flex-col">
          <div className="space-y-4 flex flex-col items-center">
            <VerticalConceptIllustration
              concept1={<ConceptIllustration color="green" />}
              concept2={<ConceptIllustration color="purple" />}
            />
            <VerticalConceptIllustration
              concept1={<ConceptIllustration color="purple" />}
              concept2={<ConceptIllustration color="blue" />}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

const ConceptText = ({
  children,
  subtitle,
  title,
}: {
  children: string;
  subtitle: string;
  title: string;
}) => {
  return (
    <>
      <h1 className="text-lg mb-2">{title}</h1>
      <h2 className="text-primary-d-700 mb-1">{subtitle}</h2>
      <p className="text-sm text-gray-d-700">{children}</p>
    </>
  );
};

type ConceptIllustrationColor = 'blue' | 'green' | 'green-light' | 'purple';

const CONCEPT_ILLUSTRATION_COLORS: Record<ConceptIllustrationColor, string> = {
  blue: 'text-[#E0F5FA]',
  green: 'text-[#A3DFB5]',
  'green-light': 'text-[#DAF8D1]',
  purple: 'text-[#E4E0FA]',
};

const ConceptIllustration = ({
  color,
}: {
  color: 'blue' | 'green' | 'green-light' | 'purple';
}) => {
  return (
    <div className={clsx('w-12 h-12', CONCEPT_ILLUSTRATION_COLORS[color])}>
      <Icon id="concept-illustration" />
    </div>
  );
};

const HorizontalConceptIllustration = ({
  children,
}: {
  children: ReactNode;
}) => {
  return (
    <div className="flex items-center space-x-2">
      <div className="w-14 h-14">
        <Icon id="respondent-group" />
      </div>
      <div className="w-6 h-6">
        <Icon id="heavy-green-arrow" />
      </div>
      {children}
    </div>
  );
};

const VerticalConceptIllustration = ({
  concept1,
  concept2,
}: {
  concept1: ReactNode;
  concept2: ReactNode;
}) => {
  return (
    <div className="flex space-x-2">
      <div className="w-14 h-14">
        <Icon id="respondent-group" />
      </div>
      <div className="mt-3">
        <div className="w-6 h-6">
          <Icon id="heavy-green-arrow" />
        </div>
      </div>
      <div className="flex flex-col items-center space-y-1">
        {concept1}
        <div className="w-6 h-6 rotate-90">
          <Icon id="heavy-green-arrow" />
        </div>
        {concept2}
      </div>
    </div>
  );
};
