import { cloneDeep } from 'lodash-es';
import { clsx } from 'clsx';
import {
  NavLink,
  Outlet,
  useNavigate,
  useOutletContext,
  useParams,
} from 'react-router-dom';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';

import { getSurveyWaveOptions } from 'util/surveyWaves';
import { isLucidSurvey } from 'util/surveys';
import { showErrorMessage, showSuccessMessage } from 'util/notifications';
import { SURVEY_STATUSES } from 'constants/surveyStatuses';
import {
  Survey,
  SurveyWave,
  SurveyWithResults,
} from '../../types/domainModels';
import { surveyQueries, useUpdateSurveyStatus } from 'hooks/backend/surveys';
import {
  useCreateRawDataExport,
  useCreateSPSSExport,
  useDownloadQuestionnaire,
  useGenerateRawExport,
  useGenerateSpssExport,
  useGenerateSummary,
} from 'hooks/backend/exports';
import { useHasRole } from '../../hooks/users';
import { useModal } from '../../hooks/modals';
import { variableQueries } from 'hooks/backend/surveyVariables';

import Button from 'components/common/forms/Button';
import ButtonLoading from 'components/common/forms/ButtonLoading';
import CloneSurveyInLucidModal from './CloneSurveyInLucid';
import CrosstabBuilderModal from './CrosstabBuilderModal';
import Dropdown, {
  DropdownButton,
  DropdownItem,
} from 'components/common/Dropdown';
import ErrorDisplay from '../common/ErrorDisplay';
import ExportsTable from './ExportsTable';
import FixedHeaderAndCollapsedSidebar from '../layout/FixedHeaderAndCollapsedSidebar';
import Icon from 'components/common/Icon';
import IconBackground from '../common/icons/IconBackground';
import IndexCard from 'components/common/IndexCard';
import Modal, { ModalHeader } from 'components/common/Modal';
import QuestionAnalytics from './QuestionAnalytics';
import RemoveRespondentsModal from './RemoveRespondentsModal';
import SaveWaveModal from '../common/SaveWaveModal';
import { Sidebar } from '../layout/DefaultLayout';
import SurveyLaunchedSidebar from './SurveyLaunchedSidebar';
import SurveySummary, { SkeletonSurveySummary } from './SurveySummary';
import SurveyWaveTitle, {
  SurveyWaveEdit,
} from 'components/surveyEdit/SurveyWaveTitle';
import SurveyWithSidebar from '../layout/SurveyWithSidebar';
import VariableAnalytics from './VariableAnalytics';
import WaveListbox from 'components/common/WaveListbox';

type SurveyLaunchedContext = {
  isLoadingSurvey: boolean;
  isLoadingSurveyResults: boolean;
  resultsWaveId: number | null | undefined;
  setSelectedWaveId(waveId: number | null): void;
  survey: Survey | undefined;
  surveyId: number;
  surveyResults: SurveyWithResults | null;
  waveIds: number[];
  waves: SurveyWave[];
};

const SurveyLauncedPage = (): JSX.Element => {
  const { id } = useParams<{ id?: string }>();
  const surveyId = Number(id);

  // "undefined" indicates that a user hasn't yet made any selection. A "null"
  // value indicates they selected to view the data for all the survey waves.
  const [selectedWaveId, setSelectedWaveId] = useState<
    number | null | undefined
  >();

  const {
    data: survey,
    error: loadSurveyError,
    isError: hasLoadSurveyError,
    isLoading: isLoadingSurvey,
  } = useQuery(surveyQueries.survey({ surveyId }));
  const latestSurveyWaveId = survey?.waveId;

  // Note: It's important we check for an "undefined" value since that indicates no selection was
  // made, as opposed to a "null" value, which indicates the user would like to view the
  // data for all waves.
  const resultsWaveId =
    selectedWaveId === undefined ? latestSurveyWaveId : selectedWaveId;

  const {
    data: fetchSurveyResultsResponse,
    error: fetchSurveyResultsError,
    isError: hasFetchSurveyResultsError,
    isLoading: isLoadingSurveyResults,
  } = useQuery({
    ...surveyQueries.surveyWithResults({
      surveyId,
      waveIds: resultsWaveId ? [resultsWaveId] : [],
    }),
    // We have to fetch the "survey without results" first because we need to determine
    // the latest wave to fetch results for. Unfortunately, the API does not expose an
    // endpoint to get all waves for a survey, so we need to take this approach. We don't
    // want to kick off this results request until we have the latest wave ID - otherwise
    // there will be two requests immediately: one for results for all waves, and then one
    // for the latest wave when fetching the survey completes.
    enabled: !!survey,
  });
  const surveyResults = fetchSurveyResultsResponse ?? null;

  const waves = surveyResults?.waves ?? [];
  const waveIds = resultsWaveId ? [resultsWaveId] : waves.map(({ id }) => id);

  const header =
    survey && surveyResults ? (
      <SurveyLaunchedHeader survey={surveyResults} waveIds={waveIds} />
    ) : (
      <div />
    );

  return (
    <FixedHeaderAndCollapsedSidebar
      header={header}
      sidebar={<Sidebar isCollapsed />}
    >
      {hasLoadSurveyError || hasFetchSurveyResultsError ? (
        <ErrorDisplay
          message={`Failed to load survey results. (${
            loadSurveyError?.message ??
            (fetchSurveyResultsError as Error | null)?.message
          })`}
        />
      ) : (
        <Outlet
          context={
            {
              isLoadingSurvey,
              isLoadingSurveyResults,
              resultsWaveId,
              setSelectedWaveId,
              survey,
              surveyId,
              surveyResults,
              waveIds,
              waves,
            } satisfies SurveyLaunchedContext
          }
        />
      )}
    </FixedHeaderAndCollapsedSidebar>
  );
};

export default SurveyLauncedPage;

const SurveyLaunchedHeader = ({
  survey,
  waveIds,
}: {
  survey: SurveyWithResults;
  waveIds: number[];
}) => {
  const navigate = useNavigate();
  const isAdmin = useHasRole('admin');

  const {
    isOpen: isCrosstabBuilderModalOpen,
    onCloseModal: onCloseCrosstabBuilderModal,
    setIsOpen: setIsCrosstabBuilderModalOpen,
  } = useModal();

  const {
    isOpen: isRemoveOpen,
    onCloseModal: onRemoveCloseModal,
    setIsOpen: setRemoveOpen,
  } = useModal();

  const {
    isOpen: isCloneOpen,
    onCloseModal: onCloneCloseModal,
    setIsOpen: setCloneIsOpen,
  } = useModal();

  const {
    isOpen: isSaveWaveModalOpen,
    onCloseModal: onCloseSaveWaveModal,
    setIsOpen: setSaveWaveModalOpen,
  } = useModal();

  return (
    <header className="flex h-full space-x-6 items-center px-6 bg-white xl:flex-wrap xl:space-x-0">
      <h1 className="w-survey-page-sidebar text-forest font-medium truncate shrink-0 xl:shrink">
        {survey.title}
      </h1>
      <nav className="xl:w-full h-full max-w-survey-summary-card grow shrink-0 xl:order-1 xl:h-16">
        <ul className="flex h-full space-x-6">
          <li>
            <HeaderLink to={`/campaign/${survey.id}`}>Analytics</HeaderLink>
          </li>
          <li>
            <HeaderLink
              isActive={false}
              onClick={() => {
                setIsCrosstabBuilderModalOpen(true);
              }}
            >
              Build Crosstab
            </HeaderLink>
          </li>
          {isAdmin && (
            <li>
              <HeaderLink
                isActive={false}
                onClick={() => {
                  setRemoveOpen(true);
                }}
              >
                Remove Respondents
              </HeaderLink>
            </li>
          )}
          <li>
            <HeaderLink to={`/campaign/${survey.id}/exports`}>
              Exports
            </HeaderLink>
          </li>
        </ul>
      </nav>
      <div className="flex space-x-3 grow items-center justify-end xl:h-16">
        {survey.wave.completes > 0 &&
          survey.status.name === SURVEY_STATUSES.COMPLETED.name && (
            <Button
              hierarchy="secondary-gray"
              icon={<Icon id="wave" />}
              iconPlacement="leading"
              onClick={() => {
                setSaveWaveModalOpen(true);
              }}
              size="sm"
            >
              Add wave
            </Button>
          )}

        <PutSurveyInDraftButton surveyId={survey.id} />

        <DownloadDropdown survey={survey} waveIds={waveIds} />

        {isAdmin &&
          survey.statusId === SURVEY_STATUSES.LIVE.id &&
          isLucidSurvey(survey) && (
            <Button
              hierarchy="secondary-gray"
              icon={<Icon id="rocket" />}
              iconPlacement="leading"
              onClick={() => {
                setCloneIsOpen(true);
              }}
              size="sm"
            >
              Boost
            </Button>
          )}
      </div>

      {isCrosstabBuilderModalOpen && (
        <CrosstabBuilderModal
          onCloseModal={() => {
            onCloseCrosstabBuilderModal();
          }}
          survey={survey}
          waves={survey.waves}
        />
      )}

      {isRemoveOpen && (
        <RemoveRespondentsModal
          onCloseModal={onRemoveCloseModal}
          surveyId={survey.id}
        />
      )}

      {isCloneOpen && (
        <CloneSurveyInLucidModal
          onCloseModal={onCloneCloseModal}
          surveyId={survey.id}
        />
      )}

      {isSaveWaveModalOpen && (
        <SaveWaveModal
          onCloseModal={onCloseSaveWaveModal}
          onWaveSaved={() => {
            onCloseSaveWaveModal();

            // The user is brought to the survey editing flow so they can make any necessary adjustments
            // for the newly created wave.
            navigate(`/campaign/edit/${survey.id}/overview`);
          }}
          surveyId={survey.id}
        />
      )}
    </header>
  );
};

const HeaderLink = (
  props:
    | {
        children: string;
        isActive: boolean;
        onClick(): void;
      }
    | {
        children: string;
        to: string;
      },
) => {
  const baseClasses = 'flex border-green items-center h-full';
  const bottomBorder = 'border-b-2';

  if ('to' in props) {
    return (
      <NavLink
        className={({ isActive }) =>
          clsx(baseClasses, { [bottomBorder]: isActive })
        }
        end
        to={props.to}
      >
        {props.children}
      </NavLink>
    );
  }

  const { isActive, onClick } = props;

  return (
    <a
      className={clsx(`${baseClasses} cursor-pointer`, {
        [bottomBorder]: isActive,
      })}
      onClick={onClick}
    >
      {props.children}
    </a>
  );
};

const PutSurveyInDraftButton = ({ surveyId }: { surveyId: number }) => {
  const {
    isOpen: isConfirmModalOpen,
    onCloseModal: onCloseConfirmModal,
    setIsOpen: setConfirmModalIsOpen,
  } = useModal();

  return (
    <>
      <Button
        hierarchy="secondary-gray"
        icon={<Icon id="pencil" />}
        iconPlacement="leading"
        onClick={() => {
          setConfirmModalIsOpen(true);
        }}
        size="sm"
      >
        Set as draft
      </Button>

      {isConfirmModalOpen && (
        <ConfirmPutSurveyInDraftModal
          onCloseModal={onCloseConfirmModal}
          surveyId={surveyId}
        />
      )}
    </>
  );
};

const ConfirmPutSurveyInDraftModal = ({
  onCloseModal,
  surveyId,
}: {
  onCloseModal(): void;
  surveyId: number;
}) => {
  const navigate = useNavigate();

  const { isPending: isUpdatingSurveyStatus, mutate: updateSurveyStatus } =
    useUpdateSurveyStatus({
      onError: (err) => {
        showErrorMessage(
          `Failed to put the survey in "Draft". Error: ${err.message}`,
        );
      },
      onSuccess: () => {
        navigate(`/campaign/edit/${surveyId}/overview`);
      },
    });

  return (
    <Modal
      header={
        <ModalHeader onClickClose={onCloseModal}>
          Put Survey In Draft
        </ModalHeader>
      }
      onCloseModal={onCloseModal}
    >
      <p>
        Change survey to "Draft" status? New responses will not be gathered
        until the survey is re-launched.
      </p>

      <div className="mt-8 flex gap-3 flex-row-reverse">
        <ButtonLoading
          grow
          hierarchy="primary"
          isLoading={isUpdatingSurveyStatus}
          onClick={() => {
            updateSurveyStatus({
              data: { statusId: SURVEY_STATUSES.DRAFT.id },
              surveyId,
            });
          }}
          size="lg"
          type="button"
        >
          Edit Survey
        </ButtonLoading>
        <Button
          grow
          hierarchy="secondary-gray"
          onClick={onCloseModal}
          size="lg"
          type="button"
        >
          Cancel
        </Button>
      </div>
    </Modal>
  );
};

const DownloadDropdown = ({
  survey,
  waveIds,
}: {
  survey: SurveyWithResults;
  waveIds: number[];
}) => {
  const navigate = useNavigate();
  const isAdmin = useHasRole('admin');
  const surveyID = survey.id;

  const {
    isPending: isDownloadingQuestionnaire,
    mutate: downloadQuestionnaire,
  } = useDownloadQuestionnaire({
    surveyId: surveyID,
  });

  const { isPending: isLoadingSummary, mutate: generateSummary } =
    useGenerateSummary({
      onError: (err) => {
        showErrorMessage(
          `Failed to generate the summary export. Error: ${err.message}`,
        );
      },
      onSuccess: () => {
        navigate(`/campaign/${surveyID}/exports`);
      },
      surveyId: surveyID,
    });

  const { isPending: isLoadingRawExport, mutate: generateRawExport } =
    useGenerateRawExport({
      onError: (err) => {
        showErrorMessage(
          `Failed to generate the raw export. Error: ${err.message}`,
        );
      },
      onSuccess: () => {
        showSuccessMessage(
          'Request successfully queued. An email is on its way!',
        );
      },
    });

  const { isPending: isCreatingRawDataExport, mutate: createRawDataExport } =
    useCreateRawDataExport({
      onError: (err) => {
        showErrorMessage(
          `Failed to generate the raw data export. Error: ${err.message}`,
        );
      },
      onSuccess: () => {
        navigate(`/campaign/${surveyID}/exports`);
      },
    });

  const { isPending: isLoadingSpssExport, mutate: generateSpssExport } =
    useGenerateSpssExport({
      onError: (err) => {
        showErrorMessage(
          `Failed to generate the SPSS export. Error: ${err.message}`,
        );
      },
    });
  const { isPending: isCreatingSPSSExport, mutate: createSPSSExport } =
    useCreateSPSSExport({
      onSuccess: () => {
        navigate(`/campaign/${surveyID}/exports`);
      },
    });

  return (
    <Dropdown
      button={
        <DropdownButton as="div">
          <ButtonLoading
            hierarchy="secondary-gray"
            icon={<Icon id="download" />}
            iconPlacement="leading"
            isLoading={
              isDownloadingQuestionnaire ||
              isLoadingRawExport ||
              isCreatingRawDataExport ||
              isLoadingSpssExport ||
              isCreatingSPSSExport ||
              isLoadingSummary
            }
            size="sm"
            type="button"
          >
            Download
          </ButtonLoading>
        </DropdownButton>
      }
    >
      <DropdownItem
        as="button"
        onClick={() => {
          downloadQuestionnaire({ includeInactiveQuestions: false });
        }}
        type="button"
      >
        Questionnaire - Active
      </DropdownItem>
      <DropdownItem
        as="button"
        onClick={() => {
          downloadQuestionnaire({ includeInactiveQuestions: true });
        }}
        type="button"
      >
        Questionnaire - All
      </DropdownItem>
      <DropdownItem
        as="button"
        onClick={() => {
          if (survey.generateNewExports) {
            createRawDataExport({
              respondentType: 'active',
              surveyId: surveyID,
              waveIds,
            });
          } else {
            generateRawExport({ surveyId: surveyID, waveIds });
          }
        }}
        type="button"
      >
        Raw Data
      </DropdownItem>
      {isAdmin && (
        <DropdownItem
          as="button"
          onClick={() => {
            if (survey.generateNewExports) {
              createRawDataExport({
                respondentType: 'all',
                surveyId: surveyID,
                waveIds,
              });
            } else {
              generateRawExport({
                surveyId: surveyID,
                useReconciles: true,
                waveIds,
              });
            }
          }}
          type="button"
        >
          Raw Data with Removed Respondents
        </DropdownItem>
      )}
      <DropdownItem
        as="button"
        onClick={() => {
          generateSummary({ survey, waveIds });
        }}
        type="button"
      >
        Summary
      </DropdownItem>
      <DropdownItem
        as="button"
        onClick={() => {
          if (survey.generateNewExports) {
            createSPSSExport({ surveyId: surveyID, waveIds });
          } else {
            generateSpssExport({ surveyId: surveyID, waveIds });
          }
        }}
        type="button"
      >
        SPSS
      </DropdownItem>
    </Dropdown>
  );
};

const PageHeader = ({ children }: { children: string }) => {
  return (
    <div className="mt-4 mb-10 text-xl text-primary-d-600 font-medium">
      {children}
    </div>
  );
};

export const SurveyAnalytics = () => {
  const {
    isLoadingSurvey,
    isLoadingSurveyResults,
    resultsWaveId,
    setSelectedWaveId,
    survey,
    surveyId,
    surveyResults,
    waveIds,
    waves,
  } = useOutletContext<SurveyLaunchedContext>();

  const [curQuestionId, setCurrentQuestionId] = useState<number | null>(null);
  const [curVariableId, setCurrentVariableId] = useState<number | null>(null);

  const isAdmin = useHasRole('admin');
  const queryClient = useQueryClient();

  const {
    data: variables = [],
    error: getVariablesError,
    isLoadingError: hasLoadVariablesError,
    isLoading: isLoadingVariables,
  } = useQuery({
    ...variableQueries.list({ surveyId }),
    refetchOnWindowFocus: false,
  });

  const waveOptions = getSurveyWaveOptions({ waves });
  const selectedWave = waves.find(({ id }) => id === resultsWaveId);
  const selectedWaveOption = waveOptions.find(
    ({ value }) => value === resultsWaveId,
  );

  const selectedQuestion = (surveyResults?.questions ?? []).find(
    ({ id }) => id === curQuestionId,
  );
  const selectedVariable = variables.find(({ id }) => id === curVariableId);

  function onQuestionActivationToggled(questionId: number) {
    const waveIds = resultsWaveId ? [resultsWaveId] : [];

    queryClient.setQueryData(
      surveyQueries.surveyWithResults({ surveyId, waveIds }).queryKey,
      (existingSurveyResults) => {
        if (!existingSurveyResults) {
          return;
        }

        const newSurveyResults = cloneDeep(existingSurveyResults);
        const question = newSurveyResults.questions.find(
          ({ id }) => id === questionId,
        );
        if (question) {
          question.isActive = !question.isActive;
        }

        return newSurveyResults;
      },
    );
    queryClient.invalidateQueries(
      surveyQueries.surveyWithResults({ surveyId, waveIds }),
    );
  }

  const sidebar =
    survey && surveyResults ? (
      <SurveyLaunchedSidebar
        curQuestionId={curQuestionId}
        curVariableId={curVariableId}
        isLoadingSurvey={isLoadingSurvey}
        isLoadingVariables={isLoadingVariables}
        loadVariablesError={
          hasLoadVariablesError && getVariablesError instanceof Error
            ? getVariablesError
            : null
        }
        onClickQuestion={(questionId) => {
          setCurrentQuestionId(questionId);
          setCurrentVariableId(null);
        }}
        onClickSurveySummary={() => {
          setCurrentQuestionId(null);
          setCurrentVariableId(null);
        }}
        onClickVariable={(variableId) => {
          setCurrentQuestionId(null);
          setCurrentVariableId(variableId);
        }}
        onQuestionActivationToggled={onQuestionActivationToggled}
        survey={surveyResults}
        variables={variables}
      />
    ) : (
      <div />
    );

  return (
    <SurveyWithSidebar sidebar={sidebar}>
      <div className="max-w-survey-summary-card">
        {isLoadingSurvey || isLoadingSurveyResults ? (
          <SkeletonSurveySummary />
        ) : (
          survey &&
          surveyResults && (
            <>
              {waves.length <= 1 ? (
                <PageHeader>{surveyResults.title}</PageHeader>
              ) : selectedWaveOption ? (
                // The h-14 class is to match the height of the "Survey Summary" selection
                // tab on the left-hand sidebar.
                <div
                  className={clsx('flex justify-between mb-6 h-14', {
                    'items-center': !selectedWave?.description,
                    'items-start': !!selectedWave?.description,
                  })}
                >
                  <div className="w-3/4">
                    <SurveyWaveTitle
                      buttons={
                        selectedWave ? (
                          <SurveyWaveEdit wave={selectedWave} />
                        ) : null
                      }
                      description={selectedWave?.description}
                      title={selectedWaveOption.label}
                    />
                  </div>

                  <WaveListbox
                    onChangeWave={setSelectedWaveId}
                    selectedWave={selectedWaveOption}
                    waveOptions={waveOptions}
                  />
                </div>
              ) : null}

              {selectedQuestion && (
                <div className="w-3/4">
                  <div className="flex items-center justify-between mb-4">
                    <div>
                      <h1 className="flex items-center space-x-2">
                        <span>Question {selectedQuestion.sort} Breakdown</span>
                        {isAdmin && (
                          <a
                            href={`/campaign/edit/${surveyId}/questions/${selectedQuestion.id}`}
                            rel="noreferrer"
                            target="_blank"
                          >
                            <IconBackground tooltip="Edit Question">
                              <div className="w-3 h-3">
                                <Icon id="pencil" />
                              </div>
                            </IconBackground>
                          </a>
                        )}
                      </h1>
                      <p className="text-dark-grey text-xs">
                        {surveyResults.title}
                      </p>
                    </div>
                  </div>
                  <QuestionAnalytics
                    cohorts={surveyResults.cohorts}
                    monadicConcepts={
                      (selectedQuestion.monadicId &&
                        surveyResults?.questions.find(
                          (q) =>
                            q.conceptTestMedia && q.conceptTestMedia.length > 1,
                        )?.conceptTestMedia) ||
                      []
                    }
                    questionId={selectedQuestion.id}
                    survey={survey}
                    waveIds={waveIds}
                  />
                </div>
              )}

              {selectedVariable && (
                <div className="w-3/4">
                  <div className="flex items-center justify-between mb-4">
                    <div>
                      <h1>{selectedVariable.title} Breakdown</h1>
                      <p className="text-dark-grey text-xs">
                        {surveyResults.title}
                      </p>
                    </div>
                    {isAdmin && (
                      <a
                        href={`/campaign/edit/${surveyId}/variables/${selectedVariable.id}`}
                        rel="noreferrer"
                        target="_blank"
                      >
                        <IconBackground tooltip="Edit Variable">
                          <div className="w-3 h-3">
                            <Icon id="pencil" />
                          </div>
                        </IconBackground>
                      </a>
                    )}
                  </div>
                  <VariableAnalytics
                    questions={surveyResults.questions}
                    survey={survey}
                    variable={selectedVariable}
                    variableId={selectedVariable.id}
                    waveIds={waveIds}
                  />
                </div>
              )}

              {!selectedQuestion && !selectedVariable && (
                <SurveySummary
                  selectedWave={selectedWave}
                  survey={surveyResults}
                  viewingWaveId={selectedWaveOption?.value}
                  waveIds={waveIds}
                />
              )}
            </>
          )
        )}
      </div>
    </SurveyWithSidebar>
  );
};

export const SurveyExports = () => {
  const { survey } = useOutletContext<SurveyLaunchedContext>();

  return (
    <div className="w-full bg-gray-50 min-h-full h-survey-sidebar">
      <div className="p-6 pb-0 grow overflow-auto h-full">
        <div className="pb-24">
          <PageHeader>Exports</PageHeader>
          <IndexCard>
            <div className="overflow-x-clip">
              <div className="min-w-[800px]">
                {survey ? (
                  <ExportsTable
                    surveyHash={survey.hash}
                    surveyTitle={survey.title}
                  />
                ) : null}
              </div>
            </div>
          </IndexCard>
        </div>
      </div>
    </div>
  );
};
