import {
  queryOptions,
  skipToken,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';

import {
  createExcelExport,
  createRawDataExport,
  createSPSSExport,
  deleteSavedExport,
  downloadExport,
  editExport,
  fetchExports,
  generateRawExport,
  generateSpssExport,
  saveExportBanners,
} from 'services/backend/exports';
import { downloadFile } from 'util/files';
import { fetchQuestionsForSurvey } from 'services/backend/questions';
import { fetchSurvey } from 'services/backend/surveys';
import {
  formDataToApiExcelExportData,
  getInitialExportFormData,
} from 'util/exports';
import { generateQuestionnaireDocx } from 'util/docx';
import { getQuestionOptions } from 'util/formOptions';
import { questionQueries } from './questions';
import { ReactQueryFunctionCallbacks } from 'types/internal';
import { showErrorMessage } from 'util/notifications';
import { Survey } from 'types/domainModels';

export const exportQueries = {
  all: () => ['exports'],

  surveyExports: ({
    showArchived,
    surveyHash,
  }: {
    showArchived: boolean;
    surveyHash: string | undefined;
  }) =>
    queryOptions({
      queryKey: [...exportQueries.all(), surveyHash, showArchived],
      queryFn: surveyHash
        ? () => fetchExports({ showArchived, surveyHash })
        : skipToken,
      enabled: !!surveyHash,
    }),
};

export function useCreateExcelExport(
  opts?: ReactQueryFunctionCallbacks<typeof createExcelExport>,
) {
  const queryClient = useQueryClient();

  return useMutation({
    ...opts,
    mutationFn: createExcelExport,
    onSuccess: (...args) => {
      queryClient.invalidateQueries({ queryKey: exportQueries.all() });

      opts?.onSuccess?.(...args);
    },
  });
}

export function useCreateRawDataExport(
  opts?: ReactQueryFunctionCallbacks<typeof createRawDataExport>,
) {
  const queryClient = useQueryClient();

  return useMutation({
    ...opts,
    mutationFn: createRawDataExport,
    onSuccess: (...args) => {
      queryClient.invalidateQueries({ queryKey: exportQueries.all() });

      opts?.onSuccess?.(...args);
    },
  });
}

export function useCreateSPSSExport(
  opts?: ReactQueryFunctionCallbacks<typeof createSPSSExport>,
) {
  const queryClient = useQueryClient();

  return useMutation({
    ...opts,
    mutationFn: createSPSSExport,
    onSuccess: (...args) => {
      queryClient.invalidateQueries({ queryKey: exportQueries.all() });

      opts?.onSuccess?.(...args);
    },
  });
}

export function useDeleteSavedExport(
  opts?: ReactQueryFunctionCallbacks<typeof deleteSavedExport>,
) {
  return useMutation({ ...opts, mutationFn: deleteSavedExport });
}

export function useDownloadExport({
  filename,
  ...rest
}: { filename: string } & ReactQueryFunctionCallbacks<typeof downloadExport>) {
  return useMutation({
    ...rest,
    mutationFn: downloadExport,
    onError: (err) => {
      showErrorMessage(`Failed to download export. Error: ${err.message}`);
    },
    onSuccess: (response) => {
      downloadFile({
        contentType: response.headers['content-type'],
        data: response.data,
        filename,
      });
    },
  });
}

export function useDownloadQuestionnaire({ surveyId }: { surveyId: number }) {
  return useMutation({
    mutationFn: async ({
      includeInactiveQuestions,
    }: {
      includeInactiveQuestions: boolean;
    }) => {
      const [survey, questions] = await Promise.all([
        fetchSurvey({ surveyId }),
        fetchQuestionsForSurvey({ surveyId }),
      ]);

      generateQuestionnaireDocx({
        isMaster: includeInactiveQuestions,
        passedQuestions: questions,
        survey,
      });
    },
    onError: (err: Error) => {
      showErrorMessage(
        `Failed to generate questionnaire download. Error: ${err.message}`,
      );
    },
  });
}

export function useEditExport(
  opts?: ReactQueryFunctionCallbacks<typeof editExport>,
) {
  const queryClient = useQueryClient();

  return useMutation({
    ...opts,
    mutationFn: editExport,
    onSuccess(...args) {
      queryClient.invalidateQueries({ queryKey: exportQueries.all() });

      opts?.onSuccess?.(...args);
    },
  });
}

export function useGenerateRawExport(
  opts?: ReactQueryFunctionCallbacks<typeof generateRawExport>,
) {
  return useMutation({ ...opts, mutationFn: generateRawExport });
}

export function useGenerateSpssExport(
  opts?: ReactQueryFunctionCallbacks<typeof generateSpssExport>,
) {
  return useMutation({ ...opts, mutationFn: generateSpssExport });
}

export function useGenerateSummary(opts: {
  onError(err: Error): void;
  onSuccess(): void;
  surveyId: number;
}) {
  const queryClient = useQueryClient();

  const { refetch: fetchSurveyQuestions } = useQuery(
    questionQueries.segmentationQuestions({ surveyId: opts.surveyId }),
  );

  const { mutateAsync: createExcelExport } = useCreateExcelExport();

  return useMutation({
    ...opts,
    mutationFn: async ({
      survey,
      waveIds,
    }: {
      survey: Survey;
      waveIds: number[];
    }) => {
      const { data } = await fetchSurveyQuestions();
      const questions = getQuestionOptions({ questions: data ?? [] });

      const exportFormData = getInitialExportFormData({ questions });
      // Not entirely sure why version 1 of the code set "stackQuestionLabels" to false but it
      // did and currently don't have the bandwidth to dig in to this so keeping the functionality
      // here the same as v1.
      exportFormData.features.stackQuestionLabels = false;
      exportFormData.waveIds = waveIds;

      createExcelExport({
        data: formDataToApiExcelExportData({
          formData: exportFormData,
        }),
        surveyId: survey.id,
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: exportQueries.all() });

      opts?.onSuccess?.();
    },
  });
}

export function useSaveExportBanners(
  opts?: ReactQueryFunctionCallbacks<typeof saveExportBanners>,
) {
  return useMutation({ ...opts, mutationFn: saveExportBanners });
}
