import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { FieldArray, useField } from 'formik';

import { apiOptionToFormOption, isOptionPopulated } from '../../util/questions';
import { Question, SurveyVariable } from '../../types/domainModels';
import { QuestionFormData, QuestionFormOption } from '../../types/forms';

import AddButton from '../common/forms/AddButton';
import DragIcon from '../common/icons/DragIcon';
import { FileUploadBulk } from 'components/common/forms/FileUpload';
import PasteOptionsPopover from './PasteOptionsPopover';
import QuestionOption from './QuestionOption';

const QuestionOptions = ({
  fieldName = 'options',
  question,
  questions,
  variables,
}: {
  fieldName?: 'labels' | 'options';
  question: Question | undefined;
  questions: Question[];
  variables: SurveyVariable[];
}) => {
  const [{ value: optionType }] =
    useField<QuestionFormData['optionType']>('optionType');
  const [{ value: options }, , optionsHelpers] =
    useField<QuestionFormOption[]>(fieldName);

  const addLabel = fieldName === 'options' ? 'Add Option' : 'Add Statement';

  return (
    <FieldArray
      name={fieldName}
      render={(arrayHelpers) => {
        return (
          <>
            <DragDropContext
              onDragEnd={({ destination, source }) => {
                if (!destination || source.index === destination.index) {
                  return;
                }

                arrayHelpers.move(source.index, destination.index);
              }}
            >
              <Droppable droppableId="droppable">
                {(provided) => (
                  <div {...provided.droppableProps} ref={provided.innerRef}>
                    {options.map((_option, index) => {
                      return (
                        <DraggableOption
                          key={index}
                          fieldName={fieldName}
                          index={index}
                          onClickRemove={() => {
                            if (options.length > 1) {
                              arrayHelpers.remove(index);
                            } else {
                              arrayHelpers.replace(
                                index,
                                apiOptionToFormOption(),
                              );
                            }
                          }}
                          optionType={optionType}
                          question={question}
                          questions={questions}
                          variables={variables}
                        />
                      );
                    })}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
            <div className="flex mt-2 space-x-4">
              {optionType?.value === 'image' && (
                <FileUploadBulk
                  onLoadFiles={(filesData) => {
                    const newOptions: QuestionFormOption[] = [];
                    for (const option of options) {
                      if (isOptionPopulated(option)) {
                        newOptions.push(option);
                      }
                    }

                    for (const fileData of filesData) {
                      const option = apiOptionToFormOption();
                      option.image.preview = fileData;
                      newOptions.push(option);
                    }

                    optionsHelpers.setValue(newOptions);
                  }}
                  trigger={<AddButton label="Bulk Upload" />}
                />
              )}
              <AddButton
                label={addLabel}
                onClick={() => {
                  arrayHelpers.push(apiOptionToFormOption());
                }}
              />
              <PasteOptionsPopover
                onAddOptions={(optionsToAdd, { shouldReplace }) => {
                  const newOptions = optionsToAdd.map((option) => {
                    return apiOptionToFormOption({
                      option: { title: option },
                    });
                  });

                  optionsHelpers.setValue(
                    shouldReplace ? newOptions : [...options, ...newOptions],
                  );
                }}
                type={fieldName === 'options' ? 'option' : 'label'}
              />
            </div>
          </>
        );
      }}
    />
  );
};

export default QuestionOptions;

const DraggableOption = ({
  fieldName,
  index,
  onClickRemove,
  optionType,
  question,
  questions,
  variables,
}: {
  fieldName: 'labels' | 'options';
  index: number;
  onClickRemove(): void;
  optionType: QuestionFormData['optionType'];
  question: Question | undefined;
  questions: Question[];
  variables: SurveyVariable[];
}) => {
  return (
    <Draggable draggableId={`option-${index}`} index={index}>
      {(provided) => {
        return (
          <div
            ref={provided.innerRef}
            className="flex items-center"
            {...provided.draggableProps}
          >
            <div
              {...provided.dragHandleProps}
              className="flex flex-col items-center justify-center h-full"
              tabIndex={-1}
            >
              <div className="w-4 h-4 text-gray-500 visible">
                <DragIcon />
              </div>
            </div>
            <QuestionOption
              key={index}
              index={index}
              isImage={optionType?.value === 'image'}
              namePrefix={fieldName}
              onClickRemove={onClickRemove}
              question={question}
              questions={questions}
              variables={variables}
            />
          </div>
        );
      }}
    </Draggable>
  );
};
