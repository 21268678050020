import { groupBy } from 'lodash-es';
import { useQuery } from '@tanstack/react-query';

import {
  QuestionWithResults,
  Survey,
  SurveyVariable,
} from '../../types/domainModels';
import { useHasRole } from 'hooks/users';
import { variableQueries } from 'hooks/backend/surveyVariables';
import { variableQuotaQueries } from 'hooks/backend/surveyVariableQuotas';

import ErrorDisplay from '../common/ErrorDisplay';
import HorizontalBarChartSimple from './HorizontalBarChartSimple';
import IndexCard from '../common/IndexCard';
import {
  QuotaProgress,
  QuotasProgressHeader,
  Requirements,
  VariableQuotasDropdown,
} from './Quotas';
import TitledCard, { TitledCardHeader } from './TitledCard';
import WordSeparator from 'components/common/WordSeparator';

const VariableAnalytics = ({
  questions,
  survey,
  variable,
  variableId,
  waveIds,
}: {
  questions: QuestionWithResults[];
  survey: Survey;
  variable: SurveyVariable;
  variableId: number;
  waveIds: number[];
}): JSX.Element => {
  const {
    data: variableResults = [],
    error: loadVariableResultsError,
    isError: hasLoadVariableResultsError,
    isLoading: isLoadingVariableResults,
  } = useQuery(variableQueries.getResults({ variableId, waveIds }));

  if (isLoadingVariableResults) {
    return <SkeletonVariableAnalytics />;
  }

  if (hasLoadVariableResultsError) {
    return (
      <ErrorDisplay
        message={`Failed to load results for variable. (${loadVariableResultsError.message})`}
      />
    );
  }

  const variableData = variableResults.map((result) => {
    return {
      count: result.count,
      percent: result.percent,
      title: result.title,
    };
  });

  return (
    <div className="space-y-4 ">
      <HorizontalBarChartSimple bars={variableData} />
      <VariableQuotas
        questions={questions}
        survey={survey}
        variable={variable}
        waveIds={waveIds}
      />
    </div>
  );
};

export default VariableAnalytics;

const SkeletonVariableAnalytics = (): JSX.Element => {
  return (
    <div className="space-y-4">
      {/* Mocks out the shape of a bar chart (poorly). */}
      <IndexCard>
        <div className="h-80 animate-pulse">
          <div className="flex justify-between">
            <div className="w-1/2 h-6 bg-light-grey" />
            <div className="flex items-center space-x-2">
              <div className="w-6 h-6 rounded-full bg-light-grey" />
              <div className="w-20 h-4 bg-light-grey" />
            </div>
          </div>
          <div className="mt-16 space-y-6">
            <div className="w-4/5 h-4 bg-light-grey" />
            <div className="w-3/5 h-4 bg-light-grey" />
            <div className="w-2/5 h-4 bg-light-grey" />
            <div className="w-1/5 h-4 bg-light-grey" />
          </div>
        </div>
      </IndexCard>
    </div>
  );
};

const VariableQuotas = ({
  questions,
  survey,
  variable,
  waveIds,
}: {
  questions: QuestionWithResults[];
  survey: Survey;
  variable: SurveyVariable;
  waveIds: number[];
}) => {
  const isAdmin = useHasRole('admin');

  const { data: variableQuotas = [] } = useQuery(
    variableQuotaQueries.forSurvey({ surveyId: survey.id, waveIds }),
  );
  const variableQuotasByVariable = groupBy(variableQuotas, 'variable.id');

  const quotasForVariable = variableQuotasByVariable[variable.id] ?? [];

  // Non-admins can't add variable quotas to a live survey.
  if (!isAdmin && quotasForVariable.length === 0) {
    return null;
  }

  return (
    <TitledCard
      header={
        <TitledCardHeader
          rightContent={
            isAdmin ? (
              <VariableQuotasDropdown
                numQuotas={quotasForVariable.length}
                questions={questions}
                variable={variable}
              />
            ) : undefined
          }
        >
          Variable Quotas
        </TitledCardHeader>
      }
    >
      {/*
       * We only show this messaging for admins since non-admins can't edit the
       * variable quotas for a live survey.
       */}
      {isAdmin && quotasForVariable.length === 0 && (
        <p className="p-4">No quotas</p>
      )}

      {quotasForVariable.length > 0 && (
        <div className="p-4">
          <div className="mb-2">
            <QuotasProgressHeader titleFirstColumn="Segment(s)" />
          </div>

          {quotasForVariable.map((quota, quotaIdx) => {
            return (
              <div key={quota.id}>
                <QuotaProgress
                  firstColumnContent={
                    <Requirements
                      logic="should"
                      requirements={quota.segments.map(({ title }) => title)}
                    />
                  }
                  numCompletes={quota.count}
                  numNeeded={quota.numberNeeded}
                  type={quota.type}
                />
                {quotaIdx !== quotasForVariable.length - 1 && (
                  <WordSeparator word="and" />
                )}
              </div>
            );
          })}
        </div>
      )}
    </TitledCard>
  );
};
