import { cloneDeep, orderBy } from 'lodash-es';
import { clsx } from 'clsx';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { ElementRef, Fragment, ReactNode, useRef, useState } from 'react';
import { FieldArray, useField } from 'formik';

import {
  ConstraintWithRange,
  ConstraintWithNumber as IConstraintWithNumber,
  ConstraintWithStatement as IConstraintWithStatement,
  getEmptyConstraintWithConcepts,
  getEmptyConstraintWithDateRange,
  getEmptyConstraintWithNumber,
  getEmptyConstraintWithOptions,
  getEmptyConstraintWithRange,
  getEmptyConstraintWithStatement,
  getEmptySegment,
  getEmptySegmentQuestion,
  getEmptyVariableQuota,
  hasConfiguredVariableQuotas,
  MODIFIER_OPTIONS_DEFAULT,
  MODIFIER_OPTION_WITHIN,
  SurveyVariableFormData,
  SurveyVariableSegmentQuestion,
} from '../../util/surveyVariables';
import { getConceptOptions, getOptionOptions } from '../../util/formOptions';
import { isIdeaPresenterQuestion } from '../../util/questions';
import {
  Question,
  QUESTION_TYPE,
  SurveyVariableModifier,
} from '../../types/domainModels';
import { QuestionGroup } from '../../types/internal';
import { ReactSelectValue } from '../../types/forms';
import { useModal } from '../../hooks/modals';

import AddButton from '../common/forms/AddButton';
import Badge from 'components/common/Badge';
import Button from '../common/forms/Button';
import DragIcon from '../common/icons/DragIcon';
import ExclamationCircleIcon from '../common/icons/ExclamationCircleIcon';
import FormDateInput from '../common/forms/FormDateInput';
import FormInput from '../common/forms/FormInput';
import FormSearchSelectInput from '../common/forms/FormSearchSelectInput';
import Icon from 'components/common/Icon';
import IconBackground from '../common/icons/IconBackground';
import Modal, { ModalHeader } from '../common/Modal';
import Select from '../common/forms/Select';
import Textarea from '../common/forms/Textarea';
import VariableQuotasModal from './VariableQuotasModal';
import WordSeparator from '../common/WordSeparator';
import XButton from '../common/forms/XButton';

export const AddUserIds = (): JSX.Element => {
  const {
    isOpen: isAddUsersModalOpen,
    onCloseModal,
    setIsOpen: setIsAddUsersModalOpen,
  } = useModal();

  const [{ value: segments }, , segmentsHelpers] =
    useField<SurveyVariableFormData['segments']>('segments');

  return (
    <div className="px-6 pb-6">
      <div className="flex items-center space-x-2">
        <h2>Define Segment by Respondent Ids</h2>
      </div>
      <div className="flex mt-4">
        <AddButton
          label="Specify respondents"
          onClick={() => {
            setIsAddUsersModalOpen(true);
          }}
        />
      </div>

      {isAddUsersModalOpen && (
        <AddUsersEnterScreen
          initialRespondentIdsToAdd={[]}
          onClickAdd={(respondentIdsToAdd) => {
            const newSegments = [
              ...cloneDeep(segments),
              {
                tempId: segments.length.toString(),
                title: '',
                questions: [],
                isUserSpecified: true,
                userIds: respondentIdsToAdd,
              },
            ];

            segmentsHelpers.setValue(newSegments);
            onCloseModal();
          }}
          onCloseModal={onCloseModal}
        />
      )}
    </div>
  );
};

const AddUsersEnterScreen = ({
  initialRespondentIdsToAdd,
  onClickAdd,
  onCloseModal,
}: {
  initialRespondentIdsToAdd: string[];
  onClickAdd(respondentIdsToAdd: string[]): void;
  onCloseModal(): void;
}): JSX.Element => {
  const respondentsField = useRef<ElementRef<'textarea'>>(null);
  const [respondentIdsToAdd, setRespondentIdsToAdd] = useState(
    initialRespondentIdsToAdd.join('\n'),
  );

  return (
    <Modal
      header={
        <ModalHeader onClickClose={onCloseModal}>Add Respondents</ModalHeader>
      }
      initialFocus={respondentsField}
      onCloseModal={onCloseModal}
      position="top"
    >
      <p className="mb-2 text-sm">
        Use the below field to paste respondent IDs to add to variable segment.
        Each respondent ID should be on its own line.
      </p>
      <Textarea
        ref={respondentsField}
        onChange={(event) => {
          setRespondentIdsToAdd(event.target.value);
        }}
        rows={15}
        size="lg"
        value={respondentIdsToAdd}
      />
      <div className="flex flex-row-reverse justify-end mt-8 gap-3">
        <Button
          disabled={!respondentIdsToAdd}
          grow
          hierarchy="primary"
          onClick={() => {
            const deduplicatedRespondentIds = new Set(
              respondentIdsToAdd.split('\n').map((id) => {
                return id.trim();
              }),
            );
            onClickAdd(Array.from(deduplicatedRespondentIds));
          }}
          size="lg"
        >
          Add
        </Button>
        <Button
          grow
          hierarchy="secondary-gray"
          onClick={onCloseModal}
          size="lg"
        >
          Cancel
        </Button>
      </div>
    </Modal>
  );
};

export const Segments = ({
  questions,
}: {
  questions: QuestionGroup<Question>[];
}) => {
  const [{ value: segments }] =
    useField<SurveyVariableFormData['segments']>('segments');

  return (
    <div className="px-6">
      <h2 className="mb-2">Segments</h2>
      <FieldArray
        name="segments"
        render={(arrayHelpers) => {
          return (
            <>
              <DragDropContext
                onDragEnd={({ destination, source }) => {
                  if (!destination || source.index === destination.index) {
                    return;
                  }

                  arrayHelpers.move(source.index, destination.index);
                }}
              >
                <Droppable droppableId="droppable">
                  {(provided) => (
                    <div {...provided.droppableProps} ref={provided.innerRef}>
                      {segments.map((_segment, index) => {
                        return (
                          <DraggableSegment
                            key={index}
                            arrayHelpers={arrayHelpers}
                            index={index}
                            questions={questions}
                          />
                        );
                      })}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>

              <div className="flex mt-4">
                <AddButton
                  label="Add Segment"
                  onClick={() => {
                    arrayHelpers.push(
                      getEmptySegment({ numExistingSegments: segments.length }),
                    );
                  }}
                />
              </div>
            </>
          );
        }}
      />
    </div>
  );
};

const DraggableSegment = ({
  arrayHelpers,
  index,
  questions,
}: {
  arrayHelpers: { remove(idx: number): void };
  index: number;
  questions: QuestionGroup<Question>[];
}) => {
  const [isHovering, setIsHovering] = useState(false);

  return (
    <Draggable draggableId={`option-${index}`} index={index}>
      {(provided, snapshot) => {
        const isDragIconVisible = isHovering || snapshot.isDragging;

        return (
          <div
            ref={provided.innerRef}
            className="relative"
            {...provided.draggableProps}
            onMouseEnter={() => {
              setIsHovering(true);
            }}
            onMouseLeave={() => {
              setIsHovering(false);
            }}
          >
            <div
              {...provided.dragHandleProps}
              className="flex absolute top-1/2 left-0 flex-col items-center justify-center transform -translate-y-1/2 -translate-x-full h-full"
              tabIndex={-1}
            >
              <div
                className={clsx('w-4 h-4 text-dark-grey', {
                  visible: isDragIconVisible,
                  invisible: !isDragIconVisible,
                })}
              >
                <DragIcon />
              </div>
            </div>
            <div key={index} className="p-4 border-b border-light-grey">
              <Segment
                index={index}
                onClickRemove={() => {
                  arrayHelpers.remove(index);
                }}
                questions={questions}
              />
            </div>
          </div>
        );
      }}
    </Draggable>
  );
};

const Segment = ({
  index,
  onClickRemove,
  questions,
}: {
  index: number;
  onClickRemove(): void;
  questions: QuestionGroup<Question>[];
}) => {
  const segmentOrGroupsFieldName = `segments.${index}.questions`;
  const userIdsFieldName = `segments.${index}.isUserSpecified`;

  const [{ value: segmentOrGroups }] = useField<
    SurveyVariableFormData['segments'][number]['questions']
  >(segmentOrGroupsFieldName);
  const [{ value: isUserSpecified }] =
    useField<SurveyVariableFormData['segments'][number]['isUserSpecified']>(
      userIdsFieldName,
    );

  return (
    <div>
      <div className="flex items-center space-x-3">
        <div className="w-80">
          <FormInput
            name={`segments.${index}.title`}
            placeholder="Segment title..."
            size="md"
          />
        </div>
        <IconBackground onClick={onClickRemove} title="Remove">
          <div className="w-3 h-3">
            <Icon id="trash" />
          </div>
        </IconBackground>
        {isUserSpecified && (
          <Badge color="primary" size="sm">
            Specified respondents
          </Badge>
        )}
      </div>
      {!isUserSpecified && (
        <div className="pl-4 w-full mt-4">
          <FieldArray
            name={segmentOrGroupsFieldName}
            render={(orGroupHelpers) => {
              return (
                <>
                  {segmentOrGroups.map((_orGroup, index) => {
                    return (
                      <Fragment key={index}>
                        <div>
                          <div className="relative">
                            <SurveyVariableOrGroup
                              fieldName={`${segmentOrGroupsFieldName}.${index}`}
                              onRemoveGroup={() => {
                                if (segmentOrGroups.length === 1) {
                                  orGroupHelpers.replace(0, [
                                    getEmptySegmentQuestion(),
                                  ]);
                                } else {
                                  orGroupHelpers.remove(index);
                                }
                              }}
                              questions={questions}
                            />
                          </div>
                        </div>
                        <WordSeparator
                          word={
                            index !== segmentOrGroups.length - 1
                              ? 'or'
                              : undefined
                          }
                        />
                      </Fragment>
                    );
                  })}
                  {
                    <div className="flex">
                      <AddButton
                        label="or"
                        onClick={() => {
                          orGroupHelpers.push([getEmptySegmentQuestion()]);
                        }}
                      />
                    </div>
                  }
                </>
              );
            }}
          />
        </div>
      )}
    </div>
  );
};

const SurveyVariableOrGroup = ({
  fieldName,
  onRemoveGroup,
  questions,
}: {
  fieldName: string;
  onRemoveGroup(): void;
  questions: QuestionGroup<Question>[];
}) => {
  const [{ value: segmentQuestions }] =
    useField<SurveyVariableFormData['segments'][number]['questions'][number]>(
      fieldName,
    );

  return (
    <FieldArray
      name={fieldName}
      render={(arrayHelpers) => {
        return (
          <>
            <div className="space-y-4">
              {segmentQuestions.map((_segmentQuestion, i) => {
                return (
                  <Fragment key={i}>
                    <SegmentQuestion
                      fieldName={`${fieldName}.${i}`}
                      onClickRemove={() => {
                        arrayHelpers.remove(i);

                        if (segmentQuestions.length === 1) {
                          onRemoveGroup();
                        }
                      }}
                      questions={questions}
                    />
                    {i !== segmentQuestions.length - 1 && (
                      <WordSeparator word="and" />
                    )}
                  </Fragment>
                );
              })}
            </div>
            <div className="flex mt-2">
              <AddButton
                label="and"
                onClick={() => {
                  arrayHelpers.push(getEmptySegmentQuestion());
                }}
              />
            </div>
          </>
        );
      }}
    />
  );
};

const SegmentQuestion = ({
  fieldName,
  onClickRemove,
  questions,
}: {
  fieldName: string;
  onClickRemove(): void;
  questions: QuestionGroup<Question>[];
}) => {
  const modifierFieldName = `${fieldName}.modifier`;
  const constraintsFieldName = `${fieldName}.constraints`;

  const [{ value: question }] = useField<
    SurveyVariableSegmentQuestion['question']
  >(`${fieldName}.question`);
  const [{ value: logicalModifier }, , logicalModifierHelpers] =
    useField<SurveyVariableSegmentQuestion['modifier']>(modifierFieldName);
  const [, , constraintsHelpers] =
    useField<SurveyVariableSegmentQuestion['constraints']>(
      constraintsFieldName,
    );

  const isNumberQuestion =
    question?.value.questionTypeId === QUESTION_TYPE.NUMBER ||
    (question?.value.questionTypeId === QUESTION_TYPE.OPEN_ENDED &&
      question?.value.features?.find((f) => f.code === 'Number'));

  let constraintsContent: ReactNode = null;
  if (!question) {
    // This empty select is just decoration before a user chooses a question. It looks
    // better to have this than an empty space.
    constraintsContent = (
      <Select
        onChange={() => {
          // pass
        }}
        options={[]}
        value={null}
      />
    );
  } else if (isIdeaPresenterQuestion(question.value)) {
    constraintsContent = (
      <ConstraintWithConcepts
        logicalModifier={logicalModifier?.value}
        namePrefix={constraintsFieldName}
        question={question.value}
      />
    );
  } else if (question.value.questionTypeId === QUESTION_TYPE.MULTIPLE_CHOICE) {
    constraintsContent = (
      <ConstraintWithOptions
        logicalModifier={logicalModifier?.value}
        namePrefix={constraintsFieldName}
        question={question.value}
      />
    );
  } else if (question.value.questionTypeId === QUESTION_TYPE.SCALE) {
    constraintsContent = (
      <ConstraintWithRanges
        logicalModifier={logicalModifier?.value}
        namePrefix={constraintsFieldName}
        optionRangeSeparator={
          <span className="text-xs">
            with a value
            <br />
            between
          </span>
        }
        question={question.value}
      />
    );
  } else if (question.value.questionTypeId === QUESTION_TYPE.RANKING) {
    constraintsContent = (
      <ConstraintWithRanges
        logicalModifier={logicalModifier?.value}
        namePrefix={constraintsFieldName}
        optionRangeSeparator={
          <span className="text-xs">
            with a rank
            <br />
            value between
          </span>
        }
        question={question.value}
      />
    );
  } else if (question.value.questionTypeId === QUESTION_TYPE.MATRIX) {
    constraintsContent = (
      <ConstraintWithStatement
        logicalModifier={logicalModifier?.value}
        namePrefix={constraintsFieldName}
        question={question.value}
      />
    );
  } else if (question.value.questionTypeId === QUESTION_TYPE.DATE) {
    constraintsContent = (
      <ConstraintWithDateRange namePrefix={constraintsFieldName} />
    );
  } else if (isNumberQuestion) {
    constraintsContent = (
      <ConstraintWithNumber namePrefix={constraintsFieldName} />
    );
  }

  let filteredModifiers = MODIFIER_OPTIONS_DEFAULT;
  if (
    isNumberQuestion ||
    question?.value.questionTypeId === QUESTION_TYPE.DATE
  ) {
    // Number questions currently only support a single modifier.
    filteredModifiers = [MODIFIER_OPTION_WITHIN];
  }

  return (
    <div className="flex w-full space-x-4">
      <div className="flex-shrink-0 w-72">
        <FormSearchSelectInput<ReactSelectValue<Question>>
          name={`${fieldName}.question`}
          onChange={(newQuestion) => {
            if (!newQuestion || newQuestion.value.id === question?.value.id) {
              return;
            }

            constraintsHelpers.setError('');

            if (
              newQuestion.value.questionTypeId === QUESTION_TYPE.RANKING ||
              newQuestion.value.questionTypeId === QUESTION_TYPE.SCALE
            ) {
              constraintsHelpers.setValue([getEmptyConstraintWithRange()]);
            } else if (
              newQuestion.value.questionTypeId === QUESTION_TYPE.MATRIX
            ) {
              constraintsHelpers.setValue([getEmptyConstraintWithStatement()]);
            } else if (
              newQuestion.value.questionTypeId === QUESTION_TYPE.NUMBER
            ) {
              logicalModifierHelpers.setValue(MODIFIER_OPTION_WITHIN);
              constraintsHelpers.setValue([getEmptyConstraintWithNumber()]);
            } else if (
              newQuestion.value.questionTypeId === QUESTION_TYPE.DATE
            ) {
              logicalModifierHelpers.setValue(MODIFIER_OPTION_WITHIN);
              constraintsHelpers.setValue([getEmptyConstraintWithDateRange()]);
            } else if (isIdeaPresenterQuestion(newQuestion.value)) {
              constraintsHelpers.setValue([getEmptyConstraintWithConcepts()]);
            } else {
              constraintsHelpers.setValue([getEmptyConstraintWithOptions()]);
            }
          }}
          options={questions}
          placeholder="Question..."
        />
      </div>
      <span className="flex-shrink-0 mt-2 text-xs">is</span>
      <div className="flex-shrink-0 w-24">
        <FormSearchSelectInput
          // Number questions currently one have one option for the modifier (which is set during
          // selection of the number question).
          isDisabled={
            !!isNumberQuestion ||
            question?.value.questionTypeId === QUESTION_TYPE.DATE
          }
          name={`${fieldName}.modifier`}
          options={filteredModifiers}
        />
      </div>
      {/*
        Constraints need some width (hence the w-px) or else they'll grow without bound if the option
        titles are long.
      */}
      <div className="flex-grow w-px">{constraintsContent}</div>
      <div className="mt-2">
        <IconBackground onClick={onClickRemove} size="small" title="Remove">
          <div className="w-3 h-3">
            <Icon id="x" />
          </div>
        </IconBackground>
      </div>
    </div>
  );
};

const ConstraintWithStatement = ({
  logicalModifier,
  namePrefix,
  question,
}: {
  logicalModifier: SurveyVariableModifier | undefined;
  namePrefix: string;
  question: Question;
}) => {
  const [{ value: constraints }] =
    useField<IConstraintWithStatement[]>(namePrefix);

  const options = orderBy(question.matrixOptions, (o) => o.sort).map(
    (option) => {
      return {
        label: option.title,
        value: option,
      };
    },
  );

  return (
    <FieldArray
      name={namePrefix}
      render={(arrayHelpers) => {
        return (
          <div>
            <div className="space-y-2">
              {constraints.map((_constraintWithStatement, index) => {
                const constraintFieldName = `${namePrefix}.${index}`;

                return (
                  <div key={index} className="flex space-x-2">
                    <div className="w-1/2">
                      <FormSearchSelectInput
                        name={`${constraintFieldName}.statement`}
                        options={getOptionOptions({
                          options: question.options,
                        })}
                      />
                    </div>
                    <div className="w-1/2">
                      <FormSearchSelectInput
                        isMulti={logicalModifier !== 'is'}
                        name={`${constraintFieldName}.options`}
                        options={options}
                      />
                    </div>
                    {constraints.length > 1 && (
                      <div className="mt-2">
                        <XButton
                          onClick={() => {
                            arrayHelpers.remove(index);
                          }}
                          title="Remove"
                        />
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
            <div className="flex mt-2">
              <AddButton
                label={logicalModifier === 'is' ? 'and' : 'or'}
                onClick={() => {
                  arrayHelpers.push(getEmptyConstraintWithStatement());
                }}
              />
            </div>
          </div>
        );
      }}
    />
  );
};

const ConstraintWithConcepts = ({
  logicalModifier,
  namePrefix,
  question,
}: {
  logicalModifier: SurveyVariableModifier | undefined;
  namePrefix: string;
  question: Question;
}): JSX.Element => {
  return (
    <FormSearchSelectInput
      isMulti={logicalModifier !== 'is'}
      name={`${namePrefix}.0.concepts`}
      options={getConceptOptions({ concepts: question.concepts ?? [] })}
    />
  );
};

const ConstraintWithOptions = ({
  logicalModifier,
  namePrefix,
  question,
}: {
  logicalModifier: SurveyVariableModifier | undefined;
  namePrefix: string;
  question: Question;
}): JSX.Element => {
  return (
    <FormSearchSelectInput
      isMulti={logicalModifier !== 'is'}
      name={`${namePrefix}.0.options`}
      options={getOptionOptions({ options: question.options })}
    />
  );
};

const ConstraintWithDateRange = ({
  namePrefix,
}: {
  namePrefix: string;
}): JSX.Element => {
  return (
    <div className="flex space-x-2">
      <FormDateInput name={`${namePrefix}[0].dateRange.start`} />
      <span className="flex-shrink-0 mt-2 text-xs">and</span>
      <FormDateInput name={`${namePrefix}[0].dateRange.end`} />
    </div>
  );
};

const ConstraintWithRanges = ({
  logicalModifier,
  namePrefix,
  optionRangeSeparator,
  question,
}: {
  logicalModifier: SurveyVariableModifier | undefined;
  namePrefix: string;
  optionRangeSeparator?: ReactNode;
  question: Question;
}): JSX.Element => {
  const [{ value: constraints }] = useField<ConstraintWithRange[]>(namePrefix);

  return (
    <FieldArray
      name={namePrefix}
      render={(arrayHelpers) => {
        return (
          <div>
            <div className="space-y-2">
              {constraints.map((_range, index) => {
                const constraintFieldName = `${namePrefix}.${index}`;

                return (
                  <div key={index} className="flex space-x-2">
                    <div className="flex-grow">
                      <FormSearchSelectInput
                        name={`${constraintFieldName}.option`}
                        options={getOptionOptions({
                          options: question.options,
                        })}
                      />
                    </div>
                    {optionRangeSeparator}
                    <div className="flex space-x-2">
                      <div className="w-16">
                        <FormInput
                          name={`${constraintFieldName}.range.start`}
                          size="md"
                          type="number"
                        />
                      </div>
                      <span className="mt-2 text-xs">and</span>
                      <div className="w-16">
                        <FormInput
                          name={`${constraintFieldName}.range.end`}
                          size="md"
                          type="number"
                        />
                      </div>
                    </div>
                    {constraints.length > 1 && (
                      <div className="mt-2">
                        <XButton
                          onClick={() => {
                            arrayHelpers.remove(index);
                          }}
                          title="Remove"
                        />
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
            <div className="flex mt-2">
              <AddButton
                label={logicalModifier === 'is' ? 'and' : 'or'}
                onClick={() => {
                  arrayHelpers.push(getEmptyConstraintWithRange());
                }}
              />
            </div>
          </div>
        );
      }}
    />
  );
};

const ConstraintWithNumber = ({
  namePrefix,
}: {
  namePrefix: string;
}): JSX.Element => {
  const [{ value: constraints }] =
    useField<IConstraintWithNumber[]>(namePrefix);

  return (
    <FieldArray
      name={namePrefix}
      render={(arrayHelpers) => {
        return (
          <div>
            <div className="space-y-2">
              {constraints.map((_range, index) => {
                const constraintFieldName = `${namePrefix}.${index}`;

                return (
                  <div key={index} className="flex space-x-2">
                    <div className="flex-grow">
                      <FormInput
                        name={`${constraintFieldName}.range.start`}
                        size="md"
                        type="number"
                      />
                    </div>
                    <span className="mt-2 text-xs">and</span>
                    <div className="flex-grow">
                      <FormInput
                        name={`${constraintFieldName}.range.end`}
                        size="md"
                        type="number"
                      />
                    </div>
                    {constraints.length > 1 && (
                      <div className="mt-2">
                        <XButton
                          onClick={() => {
                            arrayHelpers.remove(index);
                          }}
                          title="Remove"
                        />
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
          </div>
        );
      }}
    />
  );
};

export const Quotas = (): JSX.Element => {
  const [{ value: quotas }, quotasMeta, quotasHelpers] =
    useField<SurveyVariableFormData['quotas']>('quotas');

  const {
    isOpen: isQuotasModalOpen,
    onCloseModal,
    setIsOpen: setIsQuotasModalOpen,
  } = useModal();

  const hasQuotas = hasConfiguredVariableQuotas(quotas);
  const hasQuotasError = !!quotasMeta.error;

  function openQuotasModal() {
    if (quotas.length === 0) {
      quotasHelpers.setValue([getEmptyVariableQuota()]);
    }

    setIsQuotasModalOpen(true);
  }

  return (
    <div className="px-6">
      <div className="flex items-center space-x-2">
        <h2
          className={clsx({
            'text-red font-bold': hasQuotasError,
          })}
        >
          Quotas
        </h2>
        {hasQuotasError && (
          <div className="w-5 h-5 text-red">
            <ExclamationCircleIcon />
          </div>
        )}
      </div>

      {hasQuotasError && (
        <p className="mt-2 text-sm text-red">
          The quotas are misconfigured. Please adjust or remove.
        </p>
      )}

      {hasQuotas && (
        <div className="mt-2 pl-4">
          <QuotasDisplay quotas={quotas} />
        </div>
      )}

      <div className="flex mt-4">
        <AddButton
          label="Manage Quotas"
          onClick={() => {
            openQuotasModal();
          }}
        />
      </div>

      {isQuotasModalOpen && <VariableQuotasModal onCloseModal={onCloseModal} />}
    </div>
  );
};

const QuotasDisplay = ({
  quotas,
}: {
  quotas: SurveyVariableFormData['quotas'];
}): JSX.Element => {
  return (
    <ul className="list-disc pl-8 text-sm">
      {quotas.map((quota, i) => {
        const modifierValue = quota.type?.value;

        return (
          <li key={i}>
            <strong className="font-semibold">{quota.type?.label}</strong>{' '}
            {modifierValue === 'at_most' ? quota.value : ''} responses for
            segments:
            <ul className="list-circle pl-8">
              {quota.segments.map((segment, i) => {
                return <li key={i}>{segment.label}</li>;
              })}
            </ul>
          </li>
        );
      })}
    </ul>
  );
};
